<template>
    <div class="techHeatmap">

        <div class="wrapper">
            <div class="filter-block align-items-start">
                <div class="d-flex">
                    <div class="mr-3">
                        {{$t('label.period')}} {{$t('label.from')}}
                        <el-select v-model="filter.dateFrom"
                                   style="width: 63px;"
                                   @change="handleChangePeriod"
                                   popper-class="darkSelect">
                            <el-option v-for="year in years"
                                       :key="`from-${year.value}`"
                                       :label="year.value"
                                       :value="year.value"
                            ></el-option>
                        </el-select>

                    </div>
                    <div class="mr-2">
                        {{$t('label.to')}}
                        <el-select v-model="filter.dateTo"
                                   style="width: 63px;"
                                   @change="handleChangePeriod"
                                   popper-class="darkSelect">
                            <el-option v-for="year in years"
                                       :key="`to-${year.value}`"
                                       :label="year.value"
                                       :value="year.value"
                                       :disabled="year.disabled"
                            ></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="rows-cols-block"
                     v-loading="loading"
                     element-loading-background="rgba(19, 19, 19, .7)">
                    <div class="d-flex">
                        <div class="row-col-type source">
                            <span class="label">{{$t('label.source')}}: </span>
                            <el-select v-model="filter.docType"
                                       popper-class="darkSelect"
                                       @change="docType => handleSelectSource(docType, true)"
                                       placeholder="Document source">
                                <el-option v-for="(item,key) in docTypes"
                                           :key="key"
                                           :label="item.label"
                                           :value="item.name"
                                ></el-option>
                            </el-select>
                        </div>
                        <template v-show="filter.rows.length">
                            <div class="ml-2">
                                <span class="mr-1">{{$t('label.values')}}: </span>
                                <el-select v-model="filter.valType"
                                           popper-class="darkSelect"
                                           @change="apply()"
                                           placeholder="Absolute, Normalized...">
                                    <el-option key="normRow" :label="$t('dashboard.hotInRow')"
                                               value="normRow"></el-option>
                                    <el-option key="normCol" :label="$t('dashboard.hotInColumn')"
                                               value="normCol"></el-option>
                                    <el-option key="normAll" :label="$t('dashboard.hotAbsolute')"
                                               value="normAll"></el-option>
                                </el-select>
                            </div>

                            <span class="dashboardExport" @click="handleExport" v-if="filter.type !== 'cagr'">
                                    <i class="fas fa-pulse fa-spinner" v-if="exportLoading"></i>
                                    <svg v-else width="16" height="19" viewBox="0 0 16 19" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M11.2 18.5L0 15.5V3.5L11.2 0.5V18.5ZM4.58001 5.5H2.8L4.62 8.74L2.68 12.42H4.42L5.58001 9.78L6.76 12.42H8.54L6.54 8.68L8.36 5.5H6.62L5.58001 7.72L4.58001 5.5ZM12 3.5H16V15.5H12V3.5Z"
                                              fill="currentColor"/>
                                    </svg>
                                    {{$t('label.exportToExcel')}}
                                </span>
                        </template>
                    </div>
                    <div class="d-flex mt-3" v-show="filter.docType">
                        <div v-show="filter.docType" class="row-col-type">
                            <i class="far fa-thumbtack fixedValues"
                               @click="fixed.rows = !fixed.rows"
                               :class="{active: fixed.rows}"></i>
                            <span class="label">{{$t('label.rows')}}: </span>
                            <el-select v-model="filter.rowsFacetType"
                                       popper-class="darkSelect"
                                       @change="type => handleSelectType(type, 'rows')"
                                       placeholder="Row categories">
                                <el-option v-for="(item,key) in facetTypes"
                                           :key="key"
                                           :label="item.label"
                                           :value="item.type"
                                           :disabled="item.disabled"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="row-col-values" v-show="filter.rowsFacetType">
                            <el-select v-model="filter.rows"
                                       class="w-100"
                                       multiple
                                       filterable
                                       popper-class="darkSelect"
                                       @change="handleChangeFacetItem('rows')"
                                       :multiple-limit="maxRows"
                                       :placeholder="`${$t('label.rowsPlaceholder')}: ${maxRows}`">
                                <el-option v-for="(item,key) in rowsOpts"
                                           :key="key"
                                           :label="item.name"
                                           :value="item.value"
                                ></el-option>
                            </el-select>
                            <div class="clearFacetItems">
                                <i :class="['far fa-times', {active:filter.rows.length}]"
                                   @click="handleClearFacetItems('rows')"></i>
                                <el-tooltip effect="dark" :content="$t('label.checkMaxRows')" :open-delay="300">
                                    <i :class="['far fa-tasks', {active:filter.rows.length < maxRows && filter.rows.length < rowsOpts.length}]"
                                       @click="handleCheckMax('rows')"></i>
                                </el-tooltip>
                            </div>

                        </div>
                    </div>
                    <div class="d-flex mt-3" v-show="filter.docType">
                        <div v-show="filter.docType" class="row-col-type">
                            <i class="far fa-thumbtack fixedValues"
                               @click="fixed.cols = !fixed.cols"
                               :class="{active: fixed.cols}"></i>
                            <span class="label">{{$t('label.columns')}}: </span>
                            <el-select v-model="filter.colsFacetType"
                                       popper-class="darkSelect"
                                       @change="type => handleSelectType(type, 'cols')"
                                       placeholder="Column categories">
                                <el-option v-for="(item,key) in facetTypes"
                                           :key="key"
                                           :label="item.label"
                                           :value="item.type"
                                           :disabled="item.disabled"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="row-col-values" v-show="filter.colsFacetType">
                            <el-select v-model="filter.cols"
                                       class="w-100"
                                       multiple
                                       @change="handleChangeFacetItem('cols')"
                                       filterable
                                       popper-class="darkSelect"
                                       :multiple-limit="maxCols"
                                       :placeholder="`${$t('label.colsPlaceholder')}: ${maxCols}`">
                                <el-option v-for="(item,key) in colsOpts"
                                           :key="key"
                                           :label="item.name"
                                           :value="item.value"
                                ></el-option>
                            </el-select>
                            <div class="clearFacetItems">
                                <i :class="['far fa-times', {active:filter.cols.length}]"
                                   @click="handleClearFacetItems('cols')"></i>
                                <el-tooltip effect="dark" :content="$t('label.checkMaxCols')" :open-delay="300">
                                    <i :class="['far fa-tasks', {active:filter.cols.length < maxCols && filter.cols.length < colsOpts.length}]"
                                       @click="handleCheckMax('cols')"></i>
                                </el-tooltip>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="calculate-block">
                    <el-button type="primary" class="mt-1" @click="apply()">
                        {{$t('label.calculate')}}
                    </el-button>
                </div>
            </div>

            <div class="dashboard-content">
                <div class="chart-block" v-show="validQuery" ref="chartBlock">
                    <div v-if="validQuery">
                        <apexchart
                                ref="heatmapChart"
                                height="350"
                                :options="columnsChartOptions"
                                :series="chartSeries"
                                @dataPointSelection="dataPointSelectionHandler">
                        </apexchart>
                    </div>
                </div>
                <div class="hint">
                    <i class="fad fa-info-circle mr-1"></i> {{$t('hints.HorizontalScroll')}}
                </div>
                <div v-show="!validQuery" class="noResult"><h3>No result</h3></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import axios from "axios";


    export default {
        name: "TechnologyHeatmap",
        components: {},
        props: {
            filterQuery: String,
            incEvent: Object,
            customFacets: Object,
            queryFields: Object,
            isNativeQuery: Boolean
        },

        data() {
            return {
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                validQuery: false,
                loading: false,
                exportLoading: false,
                cntrlIsPressed: false,
                result: {
                    list: {},
                    total: 0,
                    offset: 0
                },
                facets: [],
                docTypes: [{
                    name: 'News',
                    disabledFacets: ['COUNTRIES', 'CPC_EXT', 'COUNTRIES_ALIGNMENT']
                }, {
                    name: 'Science',
                    disabledFacets: ['CPC_EXT']
                }, {
                    name: 'Patents',
                    disabledFacets: []
                }],
                facetName: {
                    'cpc_ext': 'PATENT CLASS (CPC)',
                    'trend': this.$t('facets.trend'),
                    'industry': this.$t('facets.industry'),
                },
                cpcName: {},
                years: [],
                minYear: 2015,
                curYear: new Date().getFullYear(),
                facetTypes: [],
                rowsOpts: [],
                colsOpts: [],
                query: {},
                request: {
                    years: [],
                    filter: {},
                },
                maxRows: 50,
                maxCols: 50,
                fixed: {
                    rows: false,
                    cols: false
                },
                filter: {
                    query: '',
                    lastQuery: '',
                    docType: 'Patents',
                    rowsFacetType: 'trend',
                    colsFacetType: 'industry',
                    valType: 'normRow',
                    rows: [],
                    cols: [],
                    dateFrom: new Date().getFullYear() - 3,
                    dateTo: new Date().getFullYear(),
                },
                sortBy: {
                    field: 'score',
                    dir: 'desc'
                },
                statuses: [],
                chartSeries: [{
                    name: 'Technology Heatmap',
                    data: []
                }],

                columnsChartOptions: {
                    chart: {
                        type: 'heatmap',
                        foreColor: '#C0C5CB',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        toolbar: {
                            show: false
                        },
                        events: {
                            click: function (event, chartContext, config) {
                                // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex].x)
                            }
                        }
                    },
                    colors: ["#bd7100"],
                    // colors: ["#aa4400"],
                    // colors: ["#a16308"],
                    // colors: ["#F1B050"],
                    // colors: ["#219653"], // green
                    plotOptions: {
                        heatmap: {
                            enableShades: true,
                            radius: 0,
                        }
                    },
                    stroke: {
                        show: true,
                        colors: ['#191919'],
                        width: 2,
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    grid: {
                        show: false,
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            align: 'right',
                            minWidth: 0,
                            maxWidth: 200,
                            style: {
                                fontSize: '12px'
                            }
                        },
                    },
                    xaxis: {
                        position: 'top',
                        color: '#C0C5CB',
                        type: 'category',
                        lines: {
                            show: false,
                        }
                    },
                },
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) {
                    if (newVal.incEvent === 'refresh') {
                        let self = this;
                        if (self.filter.lastQuery !== self.filterQuery) {

                            self.getFacets().then(() => {
                                if (self.filter.rowsFacetType) {
                                    self.changeType(self.filter.rowsFacetType, 'rows')
                                }
                                if (self.filter.colsFacetType) {
                                    self.changeType(self.filter.colsFacetType, 'cols')
                                }
                                self.refresh();
                            });
                            self.filter.lastQuery = self.filterQuery;
                        } else {
                            self.refresh();
                        }
                    } else {
                        this[newVal.incEvent](newVal.data);
                    }
                }
            },
            filterQuery(newVal) {
                console.log('new Q:', newVal);
            }
        },
        mounted() {
            this.initData();

        },
        methods: {
            refresh(params = {}) {
                let self = this,
                    query = {};
                self.request.years = [];
                for (let y = self.filter.dateFrom; y <= self.filter.dateTo; y++) {
                    self.request.years.push(y.toString());
                }
                self.loading = true;

                query.filter = {
                    "query": self.filterQuery,
                    "types": [self.filter.docType],
                    "queryFields": self.queryFields.value,
                    "subfilters": [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    "years": self.request.years,
                    "spam": false,
                    "duplicate": false
                }
                if (this.isNativeQuery) { // --- if native Query is ON ---
                    query.filter.nativeQuery = {
                        value: self.filterQuery
                    };
                    delete query.filter.query;
                }

                self.request.filter = _.cloneDeep(query.filter);

                query.columns = {
                    "type": self.filter.colsFacetType,
                    "values": self.filter.cols
                }
                if (self.customFacets[self.filter.colsFacetType]) {
                    query.columns.type = 'CUSTOM';
                    query.columns.facetId = self.filter.colsFacetType;
                }

                query.rows = {
                    "type": this.filter.rowsFacetType,
                    "values": this.filter.rows
                }
                if (self.customFacets[self.filter.rowsFacetType]) {
                    query.rows.type = 'CUSTOM';
                    query.rows.facetId = self.filter.rowsFacetType;
                }

                self.validQuery = self.filter.rows.length && self.filter.rowsFacetType && self.filter.cols.length && self.filter.colsFacetType;

                if (self.validQuery) {
                    self.query = query;
                    this.$services.analytics.heatMap(query).then(resp => {
                        const result = resp.data,
                            totalRows = result.rows.length;
                        let isRowCPC = query.rows.type.includes('CPC_');
                        let isColCPC = query.columns.type.includes('CPC_');
                        // console.log('Results: ', result);
                        self.result.list = result;

                        if ((self.$refs.chartBlock || {}).style) {
                            self.$refs.chartBlock.style.width = (self.filter.cols.length > 15)
                                ? self.filter.cols.length * 100 + 'px'
                                : 'auto';
                        }


                        self.chartSeries = result.rows.reverse().map(it => {
                            // let _name = (it.rowValue.name && it.rowValue.name.includes('->')
                            //     ? it.rowValue.value + ' ' + self.$utils.cpcNameFormat(it.rowValue.name) : it.rowValue.name)
                            return {
                                name: it.rowValue.name && !isRowCPC ? it.rowValue.name : it.rowValue.value,
                                data: it.cells.map((cell, ind) => {
                                    // cell = cell ? cell : {absolute: 0, normAll: 0, normRow: 0, normCol: 0};
                                    // cell = cell ? cell : {};
                                    // let _name = (result.columnValues[ind].name && result.columnValues[ind].name.includes('->')
                                    //     ? result.columnValues[ind].value + ' ' + self.$utils.cpcNameFormat(result.columnValues[ind].name) : result.columnValues[ind].name);
                                    return {
                                        x: isColCPC ? result.columnValues[ind].value : _.startCase(result.columnValues[ind].name || result.columnValues[ind].value),
                                        y: cell ? self.$utils.roundX(cell[self.filter.valType], 3) : 0,
                                        absolute: cell ? self.$utils.toFinHuman(cell['absolute']).number : 0,
                                        colValue: result.columnValues[ind].value,
                                        rowValue: it.rowValue.value,
                                        colType: !!(result.columnValues[ind].name && !isColCPC) ? 'CUSTOM' : self.filter.colsFacetType,
                                        colFacetId: !!(result.columnValues[ind].name && !isColCPC) ? self.filter.colsFacetType : null,
                                        rowType: !!(it.rowValue.name && !isRowCPC) ? 'CUSTOM' : self.filter.rowsFacetType,
                                        rowFacetId: !!(it.rowValue.name && !isRowCPC) ? self.filter.rowsFacetType : null,
                                    }
                                })
                            }
                        });
                        self.$refs.heatmapChart.updateOptions({
                                chart: {
                                    height: totalRows < 2 ? 100
                                        : totalRows < 4 ? totalRows * 70
                                            : totalRows * 45
                                },
                                xaxis: {
                                    labels: {
                                        rotate: 0,
                                        trim: true,
                                        hideOverlappingLabels: false,
                                        maxHeight: 250,

                                        // rotate: self.filter.cols.length > 5 ? 45 : 0,
                                        // rotateAlways: self.filter.cols.length > 5 ,
                                    },
                                    tooltip: {
                                        enabled: false
                                    }
                                },
                                tooltip: {
                                    enabled: true,
                                    y: {
                                        formatter: function (val, conf) {
                                            return self.chartSeries[conf.seriesIndex].data[conf.dataPointIndex].absolute;
                                        },
                                        title: {
                                            formatter: (seriesName) => self.cpcName[seriesName] ? self.cpcName[seriesName] : _.upperFirst(seriesName),
                                        },
                                    },
                                    x: {
                                        show: true,
                                        formatter: (x) => self.cpcName[x] || _.upperFirst(x),
                                    },
                                    // theme: 'dark'
                                },

                            }
                        );
                        // console.log('~HM~ Series: ', self.chartSeries);
                        setTimeout(() => {
                            self.loading = false;
                            let _hm = document.querySelectorAll('.apexcharts-heatmap > g rect'),
                                _h = _hm[0] ? _hm[0].height.baseVal.value : null,
                                _w = _hm[0] ? _hm[0].width.baseVal.value : null;
                            if (_h && self.filter.valType === 'normRow') {
                                // _hm.forEach(it => {
                                //     it.height.baseVal.value = _h - 5;
                                // });
                            } else if (_h && self.filter.valType === 'normCol') {
                                _hm.forEach(it => {
                                    it.width.baseVal.value = _w - 5;
                                });
                            }
                        }, 950);

                    });
                } else {
                    self.loading = false;
                }

            },
            apply() {
                this.$emit('apply');
            },
            dataPointSelectionHandler(e, chartContext, conf) {
                let self = this;
                let _data = self.chartSeries[conf.seriesIndex].data[conf.dataPointIndex];

                console.log('KW--', _data);
                let docType = self.filter.docType;
                let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'
                let facets = {
                    requests: [
                        {
                            type: yearType,
                            values: self.request.years
                        }
                    ]
                }
                if (_data.colType === _data.rowType && _data.rowFacetId === _data.colFacetId) {
                    facets.requests.push({
                        type: _data.colType,
                        values: [_data.colValue, _data.rowValue],
                        facetId: _data.colFacetId,
                        operator: "AND",
                    });
                } else {
                    facets.requests.push({
                        type: _data.colType,
                        values: [_data.colValue],
                        facetId: _data.colFacetId
                    });
                    facets.requests.push({
                        type: _data.rowType,
                        values: [_data.rowValue],
                        facetId: _data.rowFacetId
                    });

                }
                // console.log('Facets', facets, self.request.filter);
                let params = {
                    query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                    isNativeQuery: !!self.request.filter.nativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                }
                if (e.button === 0) {
                    self.$utils.openSearch(params);
                }
            },
            initData() {
                const scrollContainer = document.querySelector(".dashboard-content");
                let self = this;
                for (let year = self.minYear; year <= self.curYear; year++) {
                    self.years.push({value: year, disabled: false});
                }
                self.handleSelectSource(self.filter.docType, true);
                self.filter.lastQuery = self.filterQuery;
                self.docTypes = self.docTypes.map(it => {
                    it.label = self.$t('searchMode.source.' + it.name);
                    return it;
                })

                document.removeEventListener('keydown', () => {
                });
                document.removeEventListener('keyup', () => {
                });

                document.addEventListener('keydown', e => {
                    self.cntrlIsPressed = e.key === 'Control';
                });
                document.addEventListener('keyup', e => {
                    self.cntrlIsPressed = false;
                });

                scrollContainer.addEventListener("wheel", (evt) => {
                    if (self.cntrlIsPressed) {
                        evt.preventDefault();
                        scrollContainer.scrollLeft += evt.deltaY;
                    }
                });
            },
            getFacets(selectedFacet = null) {
                let self = this;
                self.request.years = [];
                for (let y = self.filter.dateFrom; y <= self.filter.dateTo; y++) {
                    self.request.years.push(y.toString());
                }

                let filter = {
                    query: self.filterQuery,
                    types: [self.filter.docType],
                    semantic: false,
                    subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    "years": self.request.years,
                    "spam": false,
                    "duplicate": false,
                    count: 0,
                    offset: 0,
                    facets: {
                        requests: [{
                            type: "COUNTRIES",
                            calculate: true,
                            values: selectedFacet && selectedFacet.type === 'COUNTRIES' ? selectedFacet.values : null,
                        }, {
                            type: "COUNTRIES_ALIGNMENT",
                            calculate: true,
                            values: selectedFacet && selectedFacet.type === 'COUNTRIES_ALIGNMENT' ? selectedFacet.values : null,
                        }, {
                            type: "YEAR",
                            calculate: true,
                            values: selectedFacet && selectedFacet.type === 'YEARS' ? selectedFacet.values : null,
                        }, {
                            type: "CPC_EXT",
                            calculate: true,
                            values: selectedFacet && selectedFacet.type === 'CPC_EXT' ? selectedFacet.values : null,
                        }]
                    },
                };
                if (self.isNativeQuery) { // --- if native Query is ON ---
                    filter.nativeQuery = {
                        value: self.filterQuery
                    };
                    delete filter.query;
                }

                self.loading = true;

                if (self.customFacets && Object.keys(self.customFacets).length) {
                    _.each(self.customFacets, it => {
                        filter.facets.requests.push({
                            type: "CUSTOM",
                            facetId: it.id,
                            calculate: true,
                            values: selectedFacet && selectedFacet.facetId === it.id ? selectedFacet.values : null,
                        })
                    });
                }
                return this.$services.documents.search(filter).then(resp => {
                    const result = resp.data;
                    self.facets = _.orderBy(result.facets.map(it => {
                        it.orderBy = it.orderBy ? it.orderBy
                            : it.facetId && it.facetId === 'trend' ? 1
                                : it.facetId && it.facetId === 'industry' ? 2
                                    : 10;
                        it.disabled = false;
                        return it
                    }), ['orderBy', 'facetId'], ['asc', 'desc']);
                    // console.log('Industry facets items with "steel": ', ((self.facets.find(it => it.facetId === 'industry')
                    //     || {}).items || []).filter(it => (it.value && it.value.includes('steel'))));
                    self.loading = false;

                    let cpcs = _.find(self.facets, it => it.type.includes('CPC_'));
                    if (cpcs && cpcs.items) {
                        cpcs.items.forEach(it => {
                            self.cpcName[it.value] = self.$utils.cpcNameFormat(it.name);
                        })
                    }
                });
            },
            changeType(item, opts, refresh = false) {
                let self = this;
                self.filter[opts] = self.fixed[opts] && !refresh ? self.filter[opts] : [];
                if (item !== 'YEAR') {
                    self[opts + 'Opts'] = (self.facets.find(it => (it.facetId ? it.facetId === item : it.type === item)) || {})
                        .items.filter(it => it.value).map(it => {
                            let _name = it.name || it.value;
                            if (item.includes('CPC_')) {
                                _name = self.$utils.cpcNameFormat(_name);
                                it.name = _name.substr(0, 135) + (_name.length > 135 ? ' ...' : '');
                            } else {
                                it.name = _name;
                            }
                            return it;
                        });
                }
                self.fixed[opts] = refresh ? false : self.fixed[opts];

                if (!self.fixed[opts] || refresh) {
                    if (item === 'YEAR') {
                        self.filter[opts] = [];
                        self[opts + 'Opts'] = [];
                        for (let year = self.filter.dateFrom; year <= self.filter.dateTo; year++) {
                            let _year = year.toString();
                            self.filter[opts].push(_year);
                            self[opts + 'Opts'].push({
                                name: _year,
                                value: _year
                            })
                        }
                        if (_.last(self.filter[opts]) === self.curYear.toString()) {
                            self.filter[opts].pop();
                        }
                    } else {
                        self.filter[opts] = _.map(self[opts + 'Opts'].slice(0, (opts === 'cols' ? 10 : 20)), 'value');
                    }
                }
                self.$nextTick(() => {
                    self.refresh()
                });
                // console.log('self[opts + Opts]', self[opts + 'Opts']);
            },
            handleSelectSource(item, apply = false) {
                let self = this,
                    _docType = self.docTypes.find(it => it.name === item);
                self.getFacets().then(() => {
                    self.facetTypes = self.facets.map(it => {
                        let out = _.cloneDeep(it);
                        out.type = out.facetId || out.type;
                        out.label = (out.facetId ? self.facetName[out.facetId] || _.upperCase(self.customFacets[out.facetId].name)
                            : self.$t('facets.' + (self.facetName[out.type] || out.type).toLowerCase())).toUpperCase();
                        out.disabled = _docType.disabledFacets.includes(out.type);
                        return out;
                    });
                    if (_docType.disabledFacets.includes(self.filter.colsFacetType)) {
                        self.filter.colsFacetType = '';
                        self.filter.cols = [];
                    }
                    if (_docType.disabledFacets.includes(self.filter.rowsFacetType)) {
                        self.filter.rowsFacetType = '';
                        self.filter.rows = [];
                    }
                    if (self.filter.rowsFacetType) {
                        self.changeType(self.filter.rowsFacetType, 'rows')
                    }
                    if (self.filter.colsFacetType) {
                        self.changeType(self.filter.colsFacetType, 'cols')
                    }
                    if (apply) {
                        self.$nextTick(() => {
                            self.refresh()
                        });
                    }
                    // console.log('item', item, self.filter);
                });
            },
            handleSelectType(item, opts) {
                let self = this;
                self.changeType(item, opts, true);
            },
            handleChangeFacetItem(opts) {
                let self = this;
                // self.fixed[opts] = true;
                if (opts === 'cols') {
                    let isCustom = self.customFacets[self.filter.colsFacetType];
                    self.getFacets({
                        "type": isCustom ? 'CUSTOM' : self.filter.colsFacetType,
                        "values": self.filter.cols,
                        "facetId": isCustom ? self.filter.colsFacetType : null
                    }).then(() => {
                        self.changeType(self.filter['rowsFacetType'], 'rows');
                    });
                } else {
                    let isCustom = self.customFacets[self.filter.rowsFacetType];
                    self.getFacets({
                        "type": isCustom ? 'CUSTOM' : self.filter.rowsFacetType,
                        "values": self.filter.rows,
                        "facetId": isCustom ? self.filter.rowsFacetType : null
                    }).then(() => {
                        self.changeType(self.filter['colsFacetType'], 'cols');
                    });
                }
            },
            handleChangePeriod() {
                let self = this;
                if (self.filter.rowsFacetType === 'YEAR') {
                    self.changeType(self.filter.rowsFacetType, 'rows')
                }
                if (self.filter.colsFacetType === 'YEAR') {
                    self.changeType(self.filter.colsFacetType, 'cols')
                }
                self.apply();
            },
            handleClearFacetItems(opts) {
                this.filter[opts] = [];
            },
            handleCheckMax(opts) {
                let self = this;
                self.filter[opts] = _.map(self[opts + 'Opts'].slice(0, (opts === 'cols' ? self.maxCols : self.maxRows)), 'value');
                self.$nextTick(() => {
                    self.refresh()
                });
            },
            handleExport() {
                if (!this.exportLoading) {
                    let self = this,
                        _query = _.cloneDeep(self.query),
                        fileName = 'Teqviser_Heatmap.xlsx';
                    _query.normalization = self.filter.valType.replace('norm', '').toLowerCase();
                    self.exportLoading = true;

                    axios({
                        url: this.$store.state.apiUrl + '/analytics/heatMapExport',
                        method: 'POST',
                        data: JSON.stringify(_query),
                        responseType: 'blob',
                        headers: {'Content-Type': 'application/json;charset=UTF-8'}
                    }).then((resp) => {
                        // console.log('Checked query:', query, this.filter);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(resp.data);
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.exportLoading = false;
                    })
                }
            },

        }
    }
</script>

<style lang="scss">
    .techHeatmap {
        max-height: calc(100vh - 95px);

        .rows-cols-block {
            flex: 1;
            margin-left: 10px;

            .label {
                width: 80px;
                display: inline-block;
            }

            input[type="text"] {
                font-variant: all-small-caps;
                font-size: .85rem;
            }
        }

        .row-col-type {
            .el-input {
                min-width: 220px;
            }

            &.source {
                padding-left: 17px;
            }
        }

        .row-col-values {
            flex: 1;
            margin-left: 5px;
            display: flex;
            align-items: center;
            color: #fff;
        }

        .clearFacetItems {
            margin: 0 -15px 0 5px;
            z-index: 300;
            display: flex;
            gap: 10px;

            > i {
                opacity: 0;
                padding: 2px 5px;
                cursor: pointer;
                transition: all .2s ease;

                &.active {
                    opacity: 1;
                }
            }
        }

        .fixedValues {
            opacity: .75;
            padding: 0 5px 0 1px;
            position: relative;
            top: 2px;
            cursor: pointer;

            &.active {
                color: $iq-dark-theme-primary;
                font-weight: 900;
            }
        }

        .apexcharts-xaxis text,
        .apexcharts-yaxis text {
            font-variant: all-small-caps;
        }

        .dashboardExport {
            margin-left: auto;
            margin-right: 25px !important;
        }

        .apexcharts-tooltip {
            max-width: 300px;
            white-space: normal;

            .apexcharts-tooltip-y-group {
                display: flex;
                align-items: flex-start;
            }

            .apexcharts-tooltip-text-label {
                //text-align: justify;
            }

            .apexcharts-tooltip-text-value {
                white-space: nowrap;
                margin-left: 3px;

                &::before {
                    content: ": ";
                }
            }
        }

        .dashboard-content {
            overflow-x: auto;

            .labels {
                display: flex;
                gap: 10px;
            }
        }

        .hint {
            color: #fff;
            margin: -20px 108px 10px 13px;
            font-size: 13px;
            //text-align: right;
        }
    }

    @media only screen and (max-width: 1200px) {
        #app .fst-Dashboard .techHeatmap {
            .filter-block {
                flex-direction: column;
            }

            .rows-cols-block {
                margin-left: 0;
                width: calc(100% - 15px);

                & > div {
                    display: flex;
                    justify-content: space-between;
                }

                .dashboardExport {
                    margin-right: 0 !important;
                }
            }

            .calculate-block {
                width: 100%;
                margin-top: 15px;
                text-align: right;

                .el-button {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }
</style>

<style scoped lang="scss">

</style>