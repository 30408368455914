<template>
    <div class="fst-Search wrapper" @click="handleResetPopovers()"
         :class="[
             {quickTourStarted: step.show && step.section === 'mainSearch'},
             {showStructuredQuery: showStructuredQuery}
         ]">
        <Header
                mode="search"
                ref="header"
                from="Search"
                :defaultActiveTab="defaultActiveTab"
                :isNativeQuery="isNativeQuery"
                :predicate="filterQuery"
                :tourStep="tourStep"
                @tourStep="handleTourStep"
                @showHelp="handleShowHelp"
                @quickTour="startQuickTour"
                @saveLastFilter="saveLastFilter()"
                @showUserFeedback="userFeedback"
                @changeLang="changeLang()"
        >
            <template slot="prePredicate" v-if="defaultActiveTab === 'Investment'">
                <div class="prePredicate">
                    <div>
                        <el-select
                                v-model="investor"
                                ref="investorSelect"
                                class="investorSelect"
                                value-key="value"
                                filterable
                                reserve-keyword
                                multiple
                                @change="handleUpdateInvestorLast"
                                @keydown.enter.native.prevent="handleEnterInvestor"
                                no-match-text="No match"
                                loading-text="Searching..."
                                popper-class="investorSelect"
                                :placeholder="$t('placeholder.Find_investor')"
                                remote
                                :remote-method="filterInvestors"
                                :loading="investorsLoading">
                            <template slot="prefix"><img class="prefix" :src="investor.logo"/></template>
                            <el-option v-for="item in investors"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item">
                                <img :src="item.logo"/>
                                <text-highlight :queries="invFilter.investorFilterQuery"
                                                :highlightClass="'markInvestor'">
                                    {{ item.label }}
                                </text-highlight>
                            </el-option>
                        </el-select>
                    </div>
                    <span class="clearPP" v-if="investor.length" @click="investor=[]"><i
                            class="far fa-times"></i></span>
                </div>
            </template>

            <template slot="predicate">
                <keep-alive>
                    <structured-query v-if="showStructuredQuery"
                                      ref="structuredQuery"
                                      :params="{tabPlaceholder:tabPlaceholder}"
                                      :docType="activeTab.id"
                                      @showSaveBtn="savedQueries.btnActive = true"
                                      @close="toggleStructuredQuery"
                                      @search="search">
                    </structured-query>
                    <PredicateEditor v-else-if="defaultActiveTab === 'Favorites'"
                                     v-model="favoritesTab.filter.query"
                                     key="PredicateEditor-Favorites"
                                     :isNativeQuery="isNativeQuery"
                                     @handleChangeQueryFields="handleChangeQueryFields"
                                     @apply="data => search({ state:null, isLoad:data})">
                    </PredicateEditor>
                    <PredicateEditor v-else
                                     v-model="filterQuery"
                                     key="PredicatEditor-Search"
                                     :placeholder="$t(searchPlaceholder)"
                                     :isNativeQuery="isNativeQuery"
                                     :docType="activeTab.id"
                                     @showHelp="handleShowHelp"
                                     @tourStep="handleTourStep"
                                     @handleChangeQueryFields="handleChangeQueryFields"
                                     @apply="data => search({ state:null, isLoad:data})"
                                     ref="predicateEditor"
                    >
                        <template v-slot:right>
                            <div class="control-group">
                                <div class="nativeSearch"
                                     v-if="showNativeQuery">
                                    <el-switch
                                            v-model="isNativeQuery"
                                            inactive-color="#7D8BB2"
                                            :width="25"
                                            @change="$refs.predicateEditor.apply()"
                                            active-color="#3064F2"
                                            :title="isNativeQuery ? $t('label.advSearchON') : $t('label.advSearchOFF')">
                                    </el-switch>
                                </div>

                                <el-menu :default-active="activeIndex" mode="horizontal"
                                         @select="handleExperimentalMenu">
                                    <el-submenu index="1"
                                                popper-class="experimentalMenu">
                                        <template slot="title">
                                            <div class="button">
                                                <i class="fas fa-ellipsis-h"></i>
                                            </div>
                                        </template>
                                        <el-menu-item index="structuredQuery">
                                            <i class="far fa-th-list text-primary fa-fw mr-1 "></i>
                                            <span v-html="$t('structuredQuery')"></span>
                                        </el-menu-item>
                                        <el-menu-item index="chatBot" v-if="allowed.contains('AI_CHAT')">
                                            <i class="far fa-robot text-primary fa-fw mr-1 "></i>
                                            <span v-html="$t('chatBot.title')"></span>
                                        </el-menu-item>
                                        <el-menu-item index="saveSearch">
                                            <i class="far fa-save text-primary fa-fw mr-1 "></i>
                                            <span v-html="$t('saveSearch')"></span>
                                        </el-menu-item>
                                        <el-menu-item index="createCluster"
                                                      v-if="['News','Science','ScienceCrossref','Patents'].includes(defaultActiveTab) && (isDev || allowed.contains('CREATE_CLUSTERING_REPORTS'))">
                                            <i class="far fa-chart-network text-primary fa-fw mr-1 "></i>
                                            <span v-html="$t('createCluster')"></span>
                                        </el-menu-item>
                                        <el-menu-item index="exportToExcel">
                                            <i class="far fa-file-export text-primary fa-fw mr-1 "></i>
                                            <span v-html="$t('exportToExcel')"></span>
                                        </el-menu-item>
                                        <el-menu-item index="showMoreLikeText">
                                            <i class="far fa-file-search text-primary fa-fw mr-1"></i>
                                            <span v-html="$t('moreLikeText')"></span>
                                        </el-menu-item>
                                        <el-submenu index="1-2">
                                            <template slot="title">
                                                <i class="far fa-history text-primary fa-fw mr-1 "></i>
                                                <span v-html="$t('mySearch')"></span>
                                            </template>
                                            <el-menu-item index="showSavedQueries" v-show="savedQueries.list.length">
                                                <i class="fas fa-save text-primary fa-fw mr-1 "></i>
                                                <span v-html="$t('savedSearches')"></span>
                                            </el-menu-item>
                                            <el-menu-item index="handleShowSearchHistory">
                                                <i class="fas fa-clock text-primary fa-fw mr-1 "></i>
                                                <span v-html="$t('history')"></span>
                                            </el-menu-item>
                                        </el-submenu>
                                        <el-submenu index="1-4">
                                            <template slot="title">
                                                <i class="far fa-eye mr-1 text-primary fa-fw mr-1 "></i>
                                                <span v-html="$t('showDocuments')"></span>
                                            </template>
                                            <el-menu-item index="showAllDocs">
                                                <i class="mr-1 text-primary far"
                                                   :class="[{'fa-dot-circle': showViewedDocs === null}, {'fa-circle': showViewedDocs !== null}]"></i>
                                                <span v-html="$t('allDocs')"></span>
                                            </el-menu-item>
                                            <el-menu-item index="showNewDocs">
                                                <i class="mr-1 text-primary far"
                                                   :class="[{'fa-dot-circle': showViewedDocs === false}, {'fa-circle': showViewedDocs !== false}]"></i>
                                                <span v-html="$t('notViewedDocs')"></span>
                                            </el-menu-item>
                                            <el-menu-item index="showViewedDocs">
                                                <i class="mr-1 text-primary far"
                                                   :class="[{'fa-dot-circle': showViewedDocs === true}, {'fa-circle': showViewedDocs !== true}]"></i>
                                                <span v-html="$t('viewedDocs')"></span>
                                            </el-menu-item>
                                        </el-submenu>
                                        <el-submenu index="dev" v-if="isDev">
                                            <template slot="title">
                                                <i class="far fa-code mr-1 text-primary fa-fw mr-1 "></i>
                                                Dev
                                            </template>
                                            <el-menu-item index="handleShowDuplicate">
                                                <i class="mr-1 text-primary"
                                                   :class="[showDuplicate === 'all' ? 'fas fa-check-square' : 'far fa-square' ]"></i>
                                                Show with duplicates
                                            </el-menu-item>
                                            <el-menu-item index="handleShowOnlyDuplicate">
                                                <i class="mr-1 text-primary"
                                                   :class="[showDuplicate === 'only' ? 'fas fa-check-square' : 'far fa-square' ]"></i>
                                                Show only duplicates
                                            </el-menu-item>
                                            <el-menu-item index="handleShowMarkets">
                                                <i class="mr-1 text-primary"
                                                   :class="[showMarkets === 'all' ? 'fas fa-check-square' : 'far fa-square' ]"></i>
                                                Show with markets
                                            </el-menu-item>
                                            <el-menu-item index="handleShowOnlyMarkets">
                                                <i class="mr-1 text-primary"
                                                   :class="[showMarkets === 'only' ? 'fas fa-check-square' : 'far fa-square' ]"></i>
                                                Show only markets
                                            </el-menu-item>
                                            <el-menu-item index="handleShowSPAM">
                                                <i class="mr-1 text-primary"
                                                   :class="[showSPAM ? 'fas fa-check-square' : 'far fa-square' ]"></i>
                                                Show only SPAM
                                            </el-menu-item>
                                            <el-menu-item index="devExplain">
                                                <i class="mr-1 text-primary"
                                                   :class="[dev.explain ? 'fas fa-check-square' : 'far fa-square' ]"></i>
                                                Explain
                                            </el-menu-item>
                                            <el-menu-item index="devExplain-btn" v-if="!!dev.elasticsearchRequest">
                                                <i class="mr-1 text-primary fas fa-brackets-curly"></i>
                                                Elastic request
                                            </el-menu-item>
                                            <el-menu-item index="devProfile">
                                                <i class="mr-1 text-primary"
                                                   :class="[dev.profile ? 'fas fa-check-square' : 'far fa-square' ]"></i>
                                                Profile
                                            </el-menu-item>
                                            <el-menu-item index="devProfile-btn" v-if="!!dev.profileResults">
                                                <i class="mr-1 text-primary fas fa-brackets-curly"></i>
                                                Profile results
                                            </el-menu-item>
                                        </el-submenu>
                                    </el-submenu>
                                </el-menu>
                            </div>
                        </template>
                    </PredicateEditor>
                </keep-alive>
                <div class="save-input" v-show="savedQueries.btnActive">
                    <el-input :placeholder="$t('label.enterNameOfQuery')"
                              v-model="savedQueries.title"
                              ref="saveQueryTitle"
                              @keyup.enter.native="handleSaveQueryInput('save')"
                    ></el-input>
                    <div class="control-btns">
                        <button class="save-btn"
                                @click="handleSaveQueryInput('save')">
                            <i class="fas"
                               :class="savedQueries.destination === 'cluster' ? 'fa-play' : 'fa-save'"></i>
                        </button>
                        <button class="clear-btn"
                                @click="handleSaveQueryInput('clear')">
                            <i class="far fa-times"></i>
                        </button>
                    </div>
                </div>

            </template>
        </Header>
        <div class="content" :class="{fullScreen: fullScreenMode}">
            <div class="showHideLeftMenu" @click="fullScreenMode = !fullScreenMode">
                <i :class="['fas',{'fa-chevron-left': !fullScreenMode},{'fa-chevron-right': fullScreenMode}]"></i>
            </div>

            <div class="left-sidebar">
                <div class="sumFilter" v-if="defaultActiveTab === 'Investment'">
                    <div class="label">{{$t('label.amountRaised')}}</div>
                    <div class="filter-block">
                        <el-tooltip class="item" effect="light" placement="bottom"
                                    :open-delay="600">
                            <div slot="content"
                                 v-html="$t('placeholder.youCanUse')"></div>
                            <el-input
                                    v-model="invFilter.sumFrom"
                                    prefix-icon="fa-dollar-sign far"
                                    @input="handleToFinFormat(invFilter.sumFrom, 'sumFrom')"
                                    @keyup.enter.native="$refs.predicateEditor.apply();"
                                    :placeholder="$t('from')"></el-input>
                        </el-tooltip>
                        <div class="px-1">&ndash;</div>
                        <el-tooltip class="item" effect="light" placement="bottom"
                                    :open-delay="600">
                            <div slot="content"
                                 v-html="$t('placeholder.youCanUse')"></div>
                            <el-input
                                    v-model="invFilter.sumTo"
                                    @input="handleToFinFormat(invFilter.sumTo, 'sumTo')"
                                    @keyup.enter.native="$refs.predicateEditor.apply();"
                                    prefix-icon="fa-dollar-sign far"
                                    :placeholder="$t('to')"></el-input>
                        </el-tooltip>
                        <el-button class="ml-2" type="primary" @click="$refs.predicateEditor.apply()">{{$t('search')}}
                        </el-button>
                    </div>
                </div>
                <keep-alive>
                    <SearchFavoritesFilter
                            v-if="defaultActiveTab === 'Favorites'"
                            ref="favoritesFacets"
                            v-model="favoritesTab.filter"
                            :favCounter="favCounter"
                            :assignedTagsList="assignedTagsList"
                            :filterData="favoritesTab.filterData"
                            @filterByTag="filterByTag"
                            @apply="search({})">
                    </SearchFavoritesFilter>
                    <SearchFacets v-else
                                  ref="searchFacets"
                                  v-model="activeTab.result.facets"
                                  :key="activeTab.uuid"
                                  :filter="activeTab.filter"
                                  :userSettings="userSettings"
                                  :tourStep="tourStep"
                                  @tourStep="handleTourStep"
                                  @searchProfileChanged="status=>handleSearchProfileChanged(status)"
                                  @advancedSearch="handleAdvancedSearch"
                                  @apply="$refs.predicateEditor.apply()"
                                  @selection-change="handleSelectionChangeFacet">
                    </SearchFacets>
                </keep-alive>
            </div>
            <div class="center-content"
                 :class="[{'tour-show-step': (tourStep == '3')},`curStep-${tour.curStep.index}`,`tourStep-${tourStep}`]"
                 v-loading="loading">
                <div class="tab-nav">
                    <el-menu :default-active="defaultActiveTab"
                             mode="horizontal"
                             text-color="#46494D"
                             active-text-color="#3064F2"
                             @select="id => handleSelectTab(id)"
                             :hide-timeout="0"
                             :show-timeout="0"
                    >
                        <el-menu-item index="All"
                                      v-html="$t('searchMode.source.All')"
                                      v-if="defaultActiveTab !== 'Favorites'"
                                      class="all">
                        </el-menu-item>
                        <el-menu-item v-if="defaultActiveTab === 'Favorites'" index="back"
                                      class="back">
                            <i class="fas fa-arrow-left"></i>{{ $t('label.backToSearch') }}
                        </el-menu-item>
                        <el-tooltip class="item" effect="dark" :disabled="!tab.blocked"
                                    v-for="tab in tabs" :key="tab.id"
                                    v-show="!tab.hidden"
                                    :open-delay="1000"
                                    :content="tab.blocked ? $t('upgradePlan') : ''"
                                    placement="top">
                            <el-menu-item :index="tab.id"
                                          :disabled="tab.blocked"
                                          :class="[
                                              tab.id,
                                              {highlighted: tour.highlight[tab.id]},
                                              {loading: tab.result.loading},
                                              {blocked: tab.blocked},
                                              {hide: defaultActiveTab === 'Favorites'}
                                          ]"
                            >
                                <i class="fas fa-lock mr-1" v-if="tab.blocked"></i>
                                <span class="icon" :class="tab.icon" v-if="tab.icon"></span>
                                <el-popover
                                        placement="bottom"
                                        width=""
                                        popper-class="openInNewTab"
                                        trigger="manual"
                                        v-model="tab.confirmNewTab">
                                <span class="name" v-if="tab.title" slot="reference"
                                      @contextmenu.prevent="handleOpenSourceBtn(tab)">{{ tab.title ||  tab.id}}</span>
                                    <div class="quasiContext">
                                        <div @click="handleOpenSource(tab)">{{$t('label.openInNewTab')}}</div>
                                    </div>
                                </el-popover>
                                <transition :duration="0">
                                    <el-tag v-if="defaultActiveTab === tab.id">
                                        <span>{{ numberFormat(tab.result.total) }}</span>
                                        <i class="el-icon-loading loading-icon"></i>
                                    </el-tag>
                                </transition>
                                <!--el-tag v-else type="info" effect="plain">
                                    <span>{{ numberFormat(tab.result.total) }}</span>
                                    <i class="fas fa-spinner fa-pulse"></i>
                                </el-tag-->
                            </el-menu-item>
                        </el-tooltip>
                        <!-- Favorites menu item -->
                        <el-menu-item :index="favoritesTab.id"
                                      :class="[favoritesTab.id, {'fb-auto': defaultActiveTab === 'Favorites'}]">
                            <span class="icon" :class="favoritesTab.icon"></span>
                            <span class="name" v-html="$t('Favorites')"></span>
                            <el-tag v-if="defaultActiveTab === favoritesTab.id">
                                {{ numberFormat(favoritesTab.result.total) }}
                            </el-tag>
                            <el-tag v-else type="info" effect="plain">
                                {{ numberFormat(favoritesTab.result.total) }}
                            </el-tag>
                        </el-menu-item>
                    </el-menu>
                    <div class="widgetModeTabs"
                         v-if="widgetTabs.includes(defaultActiveTab) && defaultActiveTab !== 'All'">
                        <div :class="[{active: widgetMode === 'documents'}, 'tab']"
                             @click="handleToggleWidgetMode('documents')">
                            <el-tooltip :content="$t('label.documents')" placement="top" :open-delay="1000">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3 4.5C2.44772 4.5 2 4.94772 2 5.5V6.5C2 7.05228 2.44772 7.5 3 7.5H4C4.55228 7.5 5 7.05228 5 6.5V5.5C5 4.94772 4.55228 4.5 4 4.5H3ZM8 4.5C7.44772 4.5 7 4.94772 7 5.5V6.5C7 7.05228 7.44771 7.5 8 7.5H17C17.5523 7.5 18 7.05228 18 6.5V5.5C18 4.94772 17.5523 4.5 17 4.5H8ZM2 10.5C2 9.94772 2.44772 9.5 3 9.5H4C4.55228 9.5 5 9.94772 5 10.5V11.5C5 12.0523 4.55228 12.5 4 12.5H3C2.44772 12.5 2 12.0523 2 11.5V10.5ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5V11.5C7 12.0523 7.44771 12.5 8 12.5H17C17.5523 12.5 18 12.0523 18 11.5V10.5C18 9.94772 17.5523 9.5 17 9.5H8ZM2 15.5C2 14.9477 2.44772 14.5 3 14.5H4C4.55228 14.5 5 14.9477 5 15.5V16.5C5 17.0523 4.55228 17.5 4 17.5H3C2.44772 17.5 2 17.0523 2 16.5V15.5ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5V16.5C7 17.0523 7.44771 17.5 8 17.5H17C17.5523 17.5 18 17.0523 18 16.5V15.5C18 14.9477 17.5523 14.5 17 14.5H8Z"
                                          fill="currentColor"/>
                                </svg>
                            </el-tooltip>
                            <span class="label">{{$t('label.documents')}}</span>
                        </div>
                        <div :class="[{active: widgetMode === 'analytics'}, 'tab']"
                             v-show="!activeTab.result.loading"
                             @click="handleToggleWidgetMode('analytics')">
                            <el-tooltip :content="$t('label.analytics')" placement="top" :open-delay="1000">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                            d="M5.33333 18.3333H2.83333C2.61232 18.3333 2.40036 18.2455 2.24408 18.0893C2.0878 17.933 2 17.721 2 17.5V10.8333C2 10.6123 2.0878 10.4004 2.24408 10.2441C2.40036 10.0878 2.61232 10 2.83333 10H5.33333C5.55435 10 5.76631 10.0878 5.92259 10.2441C6.07887 10.4004 6.16667 10.6123 6.16667 10.8333V17.5C6.16667 17.721 6.07887 17.933 5.92259 18.0893C5.76631 18.2455 5.55435 18.3333 5.33333 18.3333ZM11.1667 18.3333H8.66667C8.44565 18.3333 8.23369 18.2455 8.07741 18.0893C7.92113 17.933 7.83333 17.721 7.83333 17.5V3.33333C7.83333 3.11232 7.92113 2.90036 8.07741 2.74408C8.23369 2.5878 8.44565 2.5 8.66667 2.5H11.1667C11.3877 2.5 11.5996 2.5878 11.7559 2.74408C11.9122 2.90036 12 3.11232 12 3.33333V17.5C12 17.721 11.9122 17.933 11.7559 18.0893C11.5996 18.2455 11.3877 18.3333 11.1667 18.3333ZM17 18.3333H14.5C14.279 18.3333 14.067 18.2455 13.9107 18.0893C13.7545 17.933 13.6667 17.721 13.6667 17.5V8.33333C13.6667 8.11232 13.7545 7.90036 13.9107 7.74408C14.067 7.5878 14.279 7.5 14.5 7.5H17C17.221 7.5 17.433 7.5878 17.5893 7.74408C17.7455 7.90036 17.8333 8.11232 17.8333 8.33333V17.5C17.8333 17.721 17.7455 17.933 17.5893 18.0893C17.433 18.2455 17.221 18.3333 17 18.3333Z"
                                            fill="currentColor"/>
                                </svg>
                            </el-tooltip>
                            <span class="label">{{$t('label.analytics')}}</span>

                        </div>

                    </div>

                </div>
                <div class="darkness"></div>
                <div class="tour-step-note">
                    <div class="title" v-html="tour.curStep.title"></div>
                    <div class="text" v-html="tour.curStep.text"></div>
                    <div class="control fixed">
                        <div class="wrap">
                            <el-button type="default" @click="handleNextStep('skip')">
                                {{ $t('quickTour.skip') }}
                            </el-button>
                            <div class="breadcrumbs">
                                <div v-for="(crumb, ind) in tour.steps" :key="ind" @click="handleNextStep(ind)">
                                    <i :class="['fa-circle', (tour.curStep.index === ind) ? 'fas' : 'far']"></i>
                                </div>
                            </div>
                            <div class="d-flex">
                                <el-button type="default" @click="handleNextStep('back')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.818" height="12.526"
                                         viewBox="0 0 17.818 12.526">
                                        <path id="left-arrow"
                                              d="M5.812.188a.632.632,0,0,1,.9.889L2.158,5.63H17.18a.632.632,0,0,1,.638.629.64.64,0,0,1-.638.638H2.158L6.71,11.44a.645.645,0,0,1,0,.9.629.629,0,0,1-.9,0L.182,6.707a.633.633,0,0,1,0-.889Z"
                                              fill="currentColor"></path>
                                    </svg>
                                    {{ $t('quickTour.back') }}
                                </el-button>
                                <el-button type="primary" @click="handleNextStep()">
                                    {{ $t('quickTour.next') }}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.818" height="12.526"
                                         viewBox="0 0 17.818 12.526">
                                        <path id="left-arrow"
                                              d="M5.812.188a.632.632,0,0,1,.9.889L2.158,5.63H17.18a.632.632,0,0,1,.638.629.64.64,0,0,1-.638.638H2.158L6.71,11.44a.645.645,0,0,1,0,.9.629.629,0,0,1-.9,0L.182,6.707a.633.633,0,0,1,0-.889Z"
                                              fill="currentColor"></path>
                                    </svg>
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content" @contextmenu="handleContextMenu">
                    <div class="content-control"
                         :class="{'w-100': defaultActiveTab === 'MarketSize'}"
                         v-if="!['Favorites'].includes(defaultActiveTab) && widgetMode === 'documents'">
                        <template v-if="widgetMode === 'documents'">
                            <div class="sort-control">
                                <template v-if="defaultActiveTab === 'MarketSize'">
                                    <label v-html="$t('label.searchType')"></label>
                                    <el-radio-group v-model="marketDataType" @change="search({})">
                                        <el-radio-button v-for="item in marketDataTypeItems"
                                                         :key="`mdatatype-${item.value}`" :label="item.value">
                                            <el-tooltip class="item" effect="dark" placement="top" :open-delay="1500"
                                                        v-if="item.tooltip">
                                                <div slot="content" v-html="item.tooltip"></div>
                                                <span>{{ $t(item.label) }}</span>
                                            </el-tooltip>
                                            <span v-if="!item.tooltip">{{ item.label }}</span>
                                        </el-radio-button>
                                    </el-radio-group>
                                </template>
                                <div class="iq-select-like" v-else-if="defaultActiveTab !== 'Investment'">
                                    <label v-html="$t('label.sortBy')"></label>
                                    <el-select v-model="orderBy" id="searchSortBy"
                                               @change="handleSelectSelect('searchSortBy')">
                                        <el-option v-for="(item, key) in orderByItems" :key="`order-${key}`"
                                                   :label="$t(item.label)"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                                <template v-else-if="defaultActiveTab === 'Investment'">
                                    <div class="totalSum sum" v-if="investmentTab.result.sumFormatted">
                                        {{$t('label.totalInvestments')}}
                                        <span v-html="investmentTab.result.sumFormatted.numberSpanned"></span></div>
                                </template>
                            </div>
                            <div class="pattern-control iq-select-like"
                                 v-if="!['Favorites', 'MarketSize'].includes(defaultActiveTab)"
                                 v-show="patternList.filter(it=>it.docType===activeTab.id).length > 0">
                                <label v-html="$t('label.pattern')"></label>

                                <el-tooltip class="item" effect="dark" placement="top" :open-delay="1500">
                                    <div slot="content" v-html="'Pattern is a set of semantic rules which are combined with <br>' +
                                 'user\'s search query in order to solve user\'s specific search task.'"></div>
                                    <el-select v-model="pattern" id="searchPattern"
                                               @change="handleSelectSelect('searchPattern')">
                                        <el-option value="" :label="$t('label.notSelected')"
                                                   v-html="$t('label.notSelected')"></el-option>
                                        <el-option v-for="pat in patternList" :key="`pat-${pat.id}`"
                                                   v-show="pat.docType === activeTab.id"
                                                   :label="pat.name"
                                                   :value="pat.id">{{ pat.name }}
                                        </el-option>
                                    </el-select>
                                </el-tooltip>
                            </div>
                            <div v-if="defaultActiveTab === 'MarketSize'" class="year-slider-block">
                                <div>
                                    <label>{{$t('label.years')}}</label>
                                    <el-slider v-model="yearsSlider" :min="yearsSliderMin" @change="slideMarketsYear"
                                               :max="yearsSliderMax" :format-tooltip="formatSliderTooltip"></el-slider>
                                </div>
                            </div>
                            <div class="markets-year-control" v-if="defaultActiveTab === '__MarketSize__'">
                                <el-button type="text" @click="shiftMarketsYear(-1)"><i
                                        class="far fa-arrow-alt-left"></i>
                                </el-button>
                                <span class="label">Shift period</span>
                                <el-button type="text" @click="shiftMarketsYear(1)"><i
                                        class="far fa-arrow-alt-right"></i>
                                </el-button>
                            </div>
                        </template>
                        <template v-else>

                        </template>
                    </div>
                    <template v-if="widgetMode === 'documents'">

                        <div class="querySuggestion" v-if="querySuggestion.query && defaultActiveTab !== 'MarketSize'">
                            <i class="far fa-times-square clearSuggestion" @click="querySuggestion.query=''"></i>
                            <div class="origin">Showing results for: <strong>
                                <span v-html="querySuggestion.formattedOrigin"></span></strong></div>
                            <div class="suggested">
                                Search instead for: <span class="iq-link" v-html="querySuggestion.formattedQuery"
                                                          @click="handleQuerySuggestion(querySuggestion.query)"></span>
                            </div>
                        </div>
                        <div class="querySuggestion" v-if="msResult.show && defaultActiveTab === 'MarketSize'">
                            <div class="origin">No Direct mentions in Market Segment found</div>
                            <div class="suggested">
                                But there are <span class="iq-link" @click="handleSwithchToContextMS">
                            {{ msResult.contextTotal }} documents found by Сontext</span>.
                            </div>
                        </div>

                        <SearchList v-for="tab in tabs.filter(it=>!it.notDocuments)" :key="tab.id"
                                    v-show="tab === activeTab"
                                    v-model="tab.result"
                                    :ref="`searchList-${tab.id}`"
                                    :filter="tab.filter"
                                    :type="tab.id"
                                    :fullUserTagList="fullUserTagList"
                                    :showExportMenu="showExportMenu"
                                    :tourStep="tourStep"
                                    @handleSelectDoc="handleSelectDoc"
                                    @handleExportDocs="handleExportDocs"
                                    @tourStep="handleTourStep"
                                    @hideExportMenu="showExportMenu = false"
                                    @moreLikeThis="handleShowMoreLikeThis"
                                    @userTagAction="reCountFavs"
                                    @updateUserTagList="getUserTagList"
                                    @resetPattern="search({pattern: 'reset'})"
                                    @toggleFavorites="handleToggleFavorites"
                                    @infinite="(state, sortBy) => search({refresh:false, state:state, sortBy: sortBy})">
                        </SearchList>
                        <SearchList v-show="defaultActiveTab === 'Favorites'"
                                    v-model="favoritesTab.result"
                                    ref="searchList-Favorites"
                                    :filter="favoritesTab.filter"
                                    :type="favoritesTab.id"
                                    :fullUserTagList="fullUserTagList"
                                    @handleSelectDoc="handleSelectDoc"
                                    @handleExportDocs="handleExportDocs"
                                    @moreLikeThis="handleShowMoreLikeThis"
                                    @userTagAction="reCountFavs"
                                    @updateUserTagList="getUserTagList"
                                    @toggleFavorites="handleToggleFavorites"
                                    @infinite="state => search({refresh:false, state:state})">
                        </SearchList>
                        <MarketSize v-show="defaultActiveTab === 'MarketSize'"
                                    v-model="marketsTab.result"
                                    v-if="!noResult.show"
                                    ref="marketSize"
                                    :filter="marketsTab.filter"
                                    :filterQuery="filterQuery"
                                    :msResult="msResult"
                                    :marketDataType="marketDataType"
                                    :shift-year="shiftYear"
                                    @handleSwithchToContextMS="handleSwithchToContextMS"
                                    @sortBy="marketSort"
                                    @infinite="(state, sortBy) => search({refresh:false, state:state, sortBy: sortBy})">
                        </MarketSize>

                        <Investment v-if="defaultActiveTab === 'Investment' && !noResult.show"
                                    v-model="investmentTab.result"
                                    ref="investment"
                                    :showExportMenu="showExportMenu"
                                    :filter="investmentTab.filter"
                                    @handleSelectDoc="handleSelectDoc"
                                    @handleExportDocs="handleExportDocs"
                                    @hideExportMenu="showExportMenu = false"
                                    @sortBy="investmentSort"
                                    @infinite="(state, sortBy) => search({refresh:false, state:state, sortBy: sortBy})">
                        </Investment>
                        <div class="total-docs" v-if="activeTab && activeTab.result.total">
                        <span>
                            {{$utils.toFin(docsShows)}} /
                            {{$utils.toFin(activeTab.result.total)}}
                        </span>
                        </div>
                        <div class="noResult" v-if="noResult.show">
                            <div class="wrap">
                                <div class="title">No results</div>
                                <div class="description">
                                    <div v-if="msResult && msResult.contextTotal">
                                        <div class="origin">No Direct mentions in Market Segment found</div>
                                        <div class="suggested">
                                            But there are <span class="iq-link" @click="handleSwithchToContextMS">
                            {{ msResult.contextTotal }} documents found by Сontext</span>.
                                        </div>
                                    </div>
                                    <div class="mb-3" v-if="noResult.results && noResult.results.length">
                                        <div>But we've found similar results in:
                                            <div v-for="src in noResult.results" :key="src.name">
                                                <a href="javascript:" @click="handleSelectTab(src.name)"> {{ src.value
                                                    }}
                                                    {{ src.title }}</a>
                                            </div>
                                        </div>

                                    </div>
                                    <div v-if="!msResult || !msResult.contextTotal">
                                        <span v-if="noResult.results && noResult.results.length">if these don't fit your needs, modify</span>
                                        <span v-else>Modify</span>
                                        your <a href="javascript:" @click="handleShowHelp">search query</a>
                                    </div>

                                    <div class="mt-2"
                                         v-if="activeTab &&  activeTab.filter.facets && activeTab.filter.facets.requests && activeTab.filter.facets.requests.length || activeTab &&  activeTab.filter.advancedSearchParams">
                                        or <a href="javascript:" @click="search({facets: 'reset'})">clear all facets</a>
                                    </div>

                                    <div class="mt-2" v-if="pattern">
                                        or <a href="javascript:" @click="search({pattern: 'reset'})">remove the
                                        pattern</a>
                                    </div>
                                </div>
                            </div>
                            <img src="teq-search-clp.svg"/>
                        </div>
                    </template>
                    <template v-else-if="widgetMode === 'all'">
                        <!-- ...... All analytics widgets ...... -->
                        <analytics :filter="allTab.filter" ref="analytics" blockClass="view360"
                                   @quickTour="startQuickTour"
                                   docType="All"></analytics>
                    </template>
                    <template v-else>
                        <!-- ...... Analytics widgets ...... -->
                        <analytics :filter="activeTab.filter" ref="analytics" :docType="defaultActiveTab"></analytics>
                    </template>
                </div>
            </div>
            <more-like-text
                    v-if="showMoreLikeText"
                    :showMoreLikeText="showMoreLikeText"
                    :favoritesTab="favoritesTab"
                    :userInfo="userInfo"
                    v-show="showMoreLikeText"
                    @closeMoreLikeText="handleCloseMoreLikeText"
            ></more-like-text>
            <more-like-this
                    v-if="showMoreLikeThis"
                    :showMoreLikeThis="showMoreLikeThis"
                    :userInfo="userInfo"
                    :item="moreLikeThisItem"
                    @closeMoreLikeThis="handleCloseMoreLikeThis"
            ></more-like-this>
            <el-dialog
                    :title="modal.title"
                    :close-on-click-modal="false"
                    :visible.sync="modal.show"
                    :modalAppendToBody="true"
                    width="650px"
                    :custom-class="'dialog-custom text-left ' + modal.class"
            >
                <UserFeedback v-if="modal.mode === 'Feedback'"
                              @showUserFeedback="userFeedback()">
                </UserFeedback>
                <div class="modalContent" v-else-if="modal.mode === 'TourStep'">
                    <div v-if="modal.step === 'start'">
                        <div class="text-center">
                            <img src="teq-logo-icon1.svg" style="width: 36px;">
                        </div>
                        <div class="text-center">
                            <p>Пройдите небольшой тур, это облегчит начало работы.</p>
                        </div>
                        <div class="control">
                            <el-button type="default" @click="modal.show = false">
                                {{ $t('quickTour.skip') }}
                            </el-button>
                            <el-button type="primary" @click="handleNextStep('start')">
                                {{ $t('quickTour.start') }}
                            </el-button>
                        </div>

                    </div>
                    <div v-if="modal.step === 'finish'">
                        <div class="text-center">
                            <img src="teq-logo-icon1.svg" style="width: 36px;">
                        </div>
                        <div class="text-center">
                            <p>
                                Хорошего дня!
                                <img src="smile3.png" style="width: 23px;position: relative;top: 6px;left: 3px;">
                                <!--                                &#128526;-->
                            </p>
                        </div>
                        <div class="control">
                            <el-button type="default" @click="handleRestartQuickTour()">
                                {{ $t('quickTour.again') }}
                            </el-button>
                            <el-button type="primary" @click="handleFinishQuickTour">
                                {{ $t('quickTour.finish') }}
                            </el-button>
                        </div>
                    </div>
                </div>

            </el-dialog>
            <el-drawer
                    :title="$t('label.savedSearch')"
                    :visible.sync="showSavedQueries"
                    :with-header="false"
                    :wrapperClosable="false"
                    direction="ltr"
                    size="750px"
                    ref="savedQueries">
                <div class="drawer-container">
                    <div class="header">
                        <div class="title">
                            <i class="fas fa-save mr-1 iq-text-blue"></i> {{$t('label.savedSearch')}}
                        </div>
                        <div>
                            <i class="far fa-times close" @click="handleCloseSavedQueries"></i>
                        </div>

                    </div>
                    <div class="body">
                        <div class="saved-queries">
                            <div class="item" v-for="(item, key) in savedQueries.list" :key="key">
                                <div class="header">
                                    <div class="title"
                                         @click="handleLoadQuery(item.filter)">
                                        {{ item.title }} <i class="fas fa-long-arrow-right ml-1"></i>
                                    </div>
                                    <div class="delete"
                                         @click="handleDeleteSavedQuery(item.title, item.id)">
                                        <i class="far fa-trash-alt mr-1"></i>
                                    </div>
                                </div>
                                <div class="attrs">
                                    <div>
                                        <div>{{$t('label.documentType')}}: <span>{{ item.docType }}</span></div>
                                        <div v-if="item.pattern">{{ $t('label.pattern') }}
                                            <span>{{ item.pattern }}</span></div>
                                    </div>
                                    <div v-if="item.isNativeQuery">
                                        {{$t('label.advSearch')}}
                                    </div>
                                </div>
                                <div class="investors" v-if="item.investors">
                                    <div>
                                        {{$t('label.investors')}}: <span>{{ item.investors }}</span>
                                    </div>
                                </div>
                                <table class="structuredQueryTab"
                                       v-if="((item.filter.structuredQuery || {}).rows || []).length">
                                    <tr v-for="(row, ind) in item.filter.structuredQuery.rows" :key="ind">
                                        <td>
                                            <div>{{row.operator}}</div>
                                        </td>
                                        <td>
                                            <div><span v-for="(fld, fkey) in row.fields" :key="fkey">
                                            {{fld}}
                                        </span></div>
                                        </td>
                                        <td>
                                            <div>{{row.query}}</div>
                                        </td>
                                    </tr>
                                </table>
                                <div class="query" v-html="item.query" v-else></div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-drawer>
            <el-drawer
                    title="Search history"
                    :visible.sync="showSearchHistory"
                    :with-header="false"
                    :wrapperClosable="false"
                    direction="ltr"
                    ref="searchHistory">
                <div class="drawer-container">
                    <div class="header">
                        <div class="title">
                            <i class="fas fa-history mr-1 iq-text-blue"></i> {{$t('label.searchHistory')}}
                        </div>
                        <div>
                            <i class="far fa-times close" @click="handleCloseSearchHistory"></i>
                        </div>

                    </div>
                    <div class="body">
                        <div class="saved-queries">
                            <div v-for="(item, key) in searchHistory.list" :key="key" class="relative">
                                <div class="query pointer mb-2" v-html="item.formattedQuery"
                                     @click="handleLoadHistoryQuery(item)"></div>
                                <i class="fas fa-trash-alt iq-text-danger pointer delHistory"
                                   @click="handleDelHistory(item)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </el-drawer>
        </div>
        <UserNotification></UserNotification>
        <quick-tour></quick-tour>
        <ChatBot @apply="handleSearch"></ChatBot>
    </div>
</template>

<script>
    import uuid from 'uuid';
    import utils from '@/commons/utils';
    import axios from "axios";
    import Header from './../Header.vue';
    import PredicateEditor from './PredicateEditor.vue';
    import SearchFacets from './SearchFacets.vue';
    import SearchFavoritesFilter from './SearchFavoritesFilter.vue';
    import SearchList from './SearchList.vue';
    import SearchListItem from './SearchListItem.vue';
    import MoreLikeText from './MoreLikeText.vue';
    import MoreLikeThis from './MoreLikeThis.vue';
    import MarketSize from './MarketSize.vue';
    import Investment from './Investment';
    import i18n from '@/commons/i18n';
    import Help from './Help.vue';
    import UserFeedback from '@/commons/components/UserFeedbackForm';
    import UserNotification from './UserNotification.vue';
    import JsonViewer from "@/commons/components/JsonViewer.vue";
    import {services} from "@/commons/services";
    import {mapGetters, mapState} from "vuex";
    import Analytics from "./Analytics";
    import QuickTour from "../settings/QuickTour";
    import StructuredQuery from "../common/StructuredQuery";
    import ChatBot from "./ChatBot"


    export default {
        name: "Search",
        components: {
            QuickTour,
            Analytics,
            Header,
            StructuredQuery,
            PredicateEditor,
            SearchFacets,
            SearchFavoritesFilter,
            ChatBot,
            SearchList,
            MoreLikeText,
            MoreLikeThis,
            UserNotification,
            MarketSize,
            Investment,
            UserFeedback
        },
        data() {
            return {
                utils,
                superUsers: ['watson', 'chas', 'polyakova', 'mshumilova', 'kzn'],
                filterQuery: '',
                activeIndex: '1',
                queryFields: {
                    name: 'titleDescription',
                    value: ["title", "description", "systemKeywords"]
                },
                searchPlaceholder: 'placeholder.Find_news',
                showViewedDocs: null,
                userFeedbackVisible: false,
                widgetTabs: [],
                modal: {
                    title: '',
                    show: false,
                    mode: '',
                },
                fullScreenMode: false,
                customFacets: {},
                noResult: {
                    show: false,
                    results: []
                },
                tourStep: '',
                loading: false,
                isNativeQuery: false,
                showSearchHistory: false,
                showSavedQueries: false,
                showMoreLikeText: false,
                showSPAM: false,
                showDuplicate: '',
                showMarkets: '',
                showExportMenu: false,
                moreLikeThisItem: {},
                curYear: new Date().getFullYear(),
                yearsSlider: 0,
                yearsSliderMin: -2,
                yearsSliderMax: 5,
                shiftYear: new Date().getFullYear(),
                defaultActiveTab: 'News',
                prevActiveTab: 'News',
                userSettings: {
                    defaultProfile: {},
                    profileActive: false,
                    profileVisible: false,
                    locale: 'en'
                },
                tabs: [],
                searchFilter: {},
                showMoreLikeThis: false,
                docTypes: [],
                orderBy: 'relevance',
                lastOrderBy: 'relevance',
                wordStatQuery: window.localStorage.getItem('wordStatQuery'),
                firstEnter: window.localStorage.getItem('firstEnter'),
                marketSortBy: {
                    field: 'month',
                    dir: 'DESC'
                },
                investmentSortBy: {
                    field: 'investment.date',
                    dir: 'DESC'
                },
                pattern: '',
                patternList: [],
                marketDataType: 'direct',
                investors: [],
                investor: [],
                investorLast: [],
                investorsLoading: false,
                invFilter: {
                    investorFilterQuery: '',
                    investor: [],
                    sumFrom: '',
                    sumTo: ''
                },
                marketDataTypeItems: [
                    {
                        label: 'label.direct', value: 'direct',
                        tooltip: 'Direct keyword search'
                    },
                    {
                        label: 'label.context', value: 'context',
                        tooltip: 'Context keyword search'
                    },
                ],
                orderByItems: [
                    {
                        label: 'label.standardRelevance', value: 'relevance',
                        tooltip: 'Standard relevance ranges documents depending on words in the search query and document date.'
                    },
                    {
                        label: 'label.semanticRelevance', value: 'semantic',
                        tooltip: 'Semantic relevance is based on neural network which selects documents similar by meaning to the search query.'
                    },
                    {label: 'label.score', value: 'score'},
                    {label: 'label.docDate', value: 'date'},
                ],
                favoritesTab: {
                    id: 'Favorites',
                    uuid: uuid(),
                    icon: 'fas fa-star',
                    filter: {
                        offset: 0,
                        count: 20,
                        favorites: true,
                        query: '',
                        tagIds: null,
                        types: []
                    },
                    filterData: {
                        types: []
                    },
                    result: {
                        total: 0,
                        facets: [],
                        list: [],
                        loading: false
                    }
                },
                marketsTab: {
                    id: 'MarketSize',
                    uuid: uuid(),
                    filter: {
                        offset: 0,
                        count: 20,
                        query: '',
                    },
                    filterData: {
                        types: ['Company', 'Trend', 'Industry', 'URL']
                    },
                    result: {
                        total: 0,
                        facets: [],
                        list: [],
                        loading: false
                    }
                },
                investmentTab: {
                    id: 'Investment',
                    uuid: uuid(),
                    filter: {
                        offset: 0,
                        count: 20,
                        query: '',
                    },
                    result: {
                        total: 0,
                        facets: [],
                        list: [],
                        loading: false
                    }
                },
                allTab: {
                    id: 'All',
                    uuid: uuid(),
                    filter: {
                        offset: 0,
                        count: 20,
                        types: ['All'],
                        query: '',
                        facets: {
                            requests: []
                        }
                    },
                    result: {
                        total: 0,
                        facets: [],
                        list: [],
                        loading: false
                    }
                },
                savedQueries: {
                    list: [],
                    btnActive: false,
                    saving: false,
                    title: '',
                    destination: 'history'
                },
                searchHistory: {
                    list: [],
                },
                fullUserTagList: [
                    {id: 1, color: 1, name: 'Favorites'},
                    {id: 2, color: 2, name: 'Veni, vidi, vici'},
                ],
                assignedTagsList: [],
                favCounter: {
                    userTags: {},
                    inFavorites: {}
                },
                msResult: {
                    directTotal: 0,
                    contextTotal: 0,
                    show: false
                },
                querySuggestion: {
                    query: '',
                    queryHighlight: '',
                    terms: {}
                },
                dev: {
                    explain: false,
                    profile: false,
                    elasticsearchRequest: null,
                    profileResults: null,
                },
                tabPlaceholder: {
                    'News': "placeholder.Find_news",
                    'Science': "placeholder.Find_articles",
                    'ScienceOpen': "placeholder.Find_articles",
                    'Patents': "placeholder.Find_patents",
                    'Companies': "placeholder.Find_companies",
                    'MarketSize': "placeholder.Find_Market_segments",
                    'Investment': "placeholder.Find_investment",
                },
                tour: {
                    curStep: {
                        title: 'Выберите тип документов',
                        text: '',
                        index: 0
                    },
                    steps: [{
                        title: 'Выберите тип документов',
                        text: '',
                        actions: [{
                            type: 'highlight',
                            target: []
                        }],
                    }, {
                        title: 'Читайте новости',
                        text: 'Технологические и индустриальные новости с отобранных веб-ресурсов',
                        noteElement: '.center-content.tour-show-step > .tour-step-note',
                        actions: [{
                            type: 'highlight',
                            target: 'News',
                            noteCoords: {
                                left: -575,
                                right: 'auto',
                                bottom: 'auto',
                                targetElement: '.center-content .el-menu-item.News',
                                targetCoords: true,
                            },

                        }],
                    }, {
                        title: 'Изучайте научные статьи',
                        availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                        noteElement: '.center-content.tour-show-step > .tour-step-note',
                        text: 'Научные публикации, результаты конференций',
                        actions: [{
                            type: 'highlight',
                            target: ['Science', 'ScienceOpen', 'ScienceCrossref']
                        }],
                    }, {
                        title: 'Анализируйте патенты',
                        text: 'Патенты и заявки из более чем 90 патентных офисов (стран)',
                        noteElement: '.center-content.tour-show-step > .tour-step-note',
                        actions: [{
                            type: 'highlight',
                            target: 'Patents',
                            noteCoords: {
                                left: -575,
                                right: 'auto',
                                bottom: 'auto',
                                targetElement: '.center-content .el-menu-item.Patents',
                                targetCoords: true,
                            },
                        }],
                    }, {
                        title: 'Ищите компании',
                        availableForRole: 'COMPANIES_SEARCH',
                        noteElement: '.center-content.tour-show-step > .tour-step-note',
                        text: 'Стартапы и крупные компании в разрезе технологий и отраслей',
                        actions: [{
                            type: 'highlight',
                            target: 'Companies',
                            noteCoords: {
                                left: -590,
                                right: 'auto',
                                bottom: 'auto',
                                targetElement: '.center-content .el-menu-item.Companies',
                                targetCoords: true,
                            },
                        }],
                    }, {
                        title: 'Исследуйте рынки',
                        text: 'Аналитика по объемам рынка, собранная от ведущих экспертов',
                        noteElement: '.center-content.tour-show-step > .tour-step-note',
                        actions: [{
                            type: 'highlight',
                            target: 'MarketSize',
                            noteCoords: {
                                left: -575,
                                right: 'auto',
                                bottom: 'auto',
                                targetElement: '.center-content .el-menu-item.MarketSize',
                                targetCoords: true,
                            },

                        }],
                    }, {
                        title: 'Анализируйте инвестиции',
                        availableForRole: 'COMPANIES_SEARCH',
                        text: 'Венчурные сделки и M&A всего мира',
                        noteElement: '.center-content.tour-show-step > .tour-step-note',
                        actions: [{
                            type: 'highlight',
                            target: 'Investment',
                            noteCoords: {
                                left: -580,
                                right: 'auto',
                                bottom: 'auto',
                                targetElement: '.center-content .el-menu-item.Investment',
                                targetCoords: true,
                            },
                        }],
                    }],
                    highlight: {
                        News: false,
                        Science: false,
                        Patent: false,
                        Companies: false,
                        MarketSize: false,
                        Investment: false,
                    }
                },

            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo,
                notionsDict: state => state.notions,
                showStructuredQuery: state => state.showStructuredQuery,
                structuredQuery: state => state.structuredQuery
            }),
            ...mapGetters({
                step: 'QTStatus'
            }),
            globalFilter() {
                return this.$store.getters.filter;
            },
            clusterFilterReportId() {
                return this.$store.getters.filter.clusterFilter.reportId;
            },
            showNativeQuery() {
                return (this.userInfo.roles || []).includes('ADVANCED_SEARCH') && !this.showStructuredQuery;
            },
            activeTab() {
                return this.defaultActiveTab === 'All' ? this.allTab
                    : this.tabs.find(tab => tab.id === this.defaultActiveTab);
            },
            allowed() {
                return this.$store.state.allowedActions;
            },
            isDev() {
                return this.userInfo.roles.indexOf('DEV') >= 0;
            },
            isAdmin() {
                return this.isDev || this.userInfo.roles.indexOf('ADMIN') >= 0;
            },
            widgets() {
                return this.$store.getters.swList;
            },
            widgetMode() {
                return this.$store.getters.swList.mode
            },

            docsShows() {
                let _delta = this.activeTab.filter.offset + this.activeTab.filter.count
                return _delta < this.activeTab.result.total ? _delta : this.activeTab.result.total;
            }
        },
        watch: {
            pattern() {
                // this.resizeInput('.pattern-control .el-input__inner', '500 14px Roboto');
            },
            clusterFilterReportId(newVal) {
                let self = this;
                if (!newVal) {
                    self.orderByItems = self.orderByItems.filter(it => it.value !== 'clusterRelevance');
                    self.orderBy = self.lastOrderBy;
                }
            },
        },
        created() {
            this.refreshTabs();

        },
        mounted() {
            let self = this;
            // console.log('>>> :sysInfo : >>>', self.userInfo);

            // --- Back button prevent ---
            history.pushState(null, document.title, location.href);
            window.addEventListener('popstate', function (event) {
                history.pushState(null, document.title, location.href);
            });

            services.system.info().then(function (resp) {

                if (self.isDev) {
                    console.log('~~~ :sysInfo : ~~~', resp.data);
                }
                _.each(resp.data.facets, it => {
                    self.customFacets[it.id] = it;
                });
                self.$store.commit('customFacets', self.customFacets);
                self.$store.commit('lastYears', resp.data.lastYear);
                // console.log('store', self.$store.state);

                if (self.isAdmin) {
                    let _loc = window.localStorage.getItem('showCustomFacets'),
                        state = !(_loc && _loc === 'false');
                    self.$store.commit('showCustomFacets', state);
                }


                self.userSettings = resp.data.userSettings || {};
                if (self.userSettings.locale) {
                    i18n.locale = self.userSettings.locale;
                    self.$store.commit('locale', self.userSettings.locale);
                    self.refreshTabs();
                }

                let lastSearch = JSON.parse(window.sessionStorage.getItem('lastSearchProfile') || '{}');

                let globalParams = JSON.parse(window.sessionStorage.getItem('globalParams') || '{}');
                if (globalParams.from !== 'search') {
                    self.filterQuery = globalParams.query;
                    self.isNativeQuery = typeof (globalParams.isNativeQuery) !== 'undefined' ? globalParams.isNativeQuery : false;
                    self.queryFields = typeof globalParams.queryFields !== 'undefined' ? globalParams.queryFields : self.queryFields;
                    self.$refs.predicateEditor.queryFields = self.queryFields.name || 'titleDescription';
                }
                // console.log('globalParams: ', globalParams);

                if (self.wordStatQuery) {
                    let wordStatQuery = JSON.parse(self.wordStatQuery);
                    wordStatQuery.docType = wordStatQuery.docType ? wordStatQuery.docType
                        : wordStatQuery.types ? wordStatQuery.types[0]
                            : '';

                    if (wordStatQuery.docType) {
                        // self.loading = true;
                        self.defaultActiveTab = wordStatQuery.docType;
                        self.searchPlaceholder = self.tabPlaceholder[wordStatQuery.docType];
                        // if (wordStatQuery.from === 'wordStat') {
                        self.filterQuery = wordStatQuery.query;

                        // }
                        let _tab = self.tabs.find(it => it.id === wordStatQuery.docType);
                        _tab.result.loading = true;
                        _tab.filter.facets = wordStatQuery.facets ? wordStatQuery.facets : {};
                        self.showSpam = false;
                        self.showDuplicate = '';
                        _tab.filter.clusterFilter = wordStatQuery.clusterFilter;
                        self.isNativeQuery = typeof (wordStatQuery.isNativeQuery) !== 'undefined' ? wordStatQuery.isNativeQuery : false;
                        if (wordStatQuery.isNativeQuery && wordStatQuery.nativeQuery) {
                            self.filterQuery = wordStatQuery.nativeQuery.value;
                            _tab.filter.nativeQuery = wordStatQuery.nativeQuery;
                        }
                        if (wordStatQuery.clusterFilter && (wordStatQuery.clusterFilter.clusterIds
                            || wordStatQuery.clusterFilter.points || []).length) {
                            self.$store.commit('updFilter', wordStatQuery);
                        }
                        // self.tabs.forEach(it => it.filter.facets = wordStatQuery.facets ? wordStatQuery.facets : {});
                        // console.log('wordStatQ:',wordStatQuery);
                        // console.log('_tab:',_tab);
                        if (wordStatQuery.docType === 'Companies') {
                            self.orderByItems.push({label: 'label.companyRank', value: 'companyRank'})
                        }
                        if (wordStatQuery.clusterFilter) {
                            self.orderByItems.unshift({label: 'label.clusterRelevance', value: 'clusterRelevance'});
                            if (wordStatQuery.clusterFilter.addScore) {
                                self.orderBy = 'clusterRelevance';
                            }
                        }

                        if (wordStatQuery.orderBy) {
                            self.orderBy = wordStatQuery.orderBy;
                        }
                        if (wordStatQuery.investmentSortBy) {
                            self.investmentSortBy = wordStatQuery.investmentSortBy;
                        }
                        if (wordStatQuery.investor) {
                            self.investors = [{
                                label: wordStatQuery.investor.title,
                                logo: wordStatQuery.investor.urlLogo,
                                value: wordStatQuery.investor.id,
                            }];
                            self.$nextTick(() => {
                                self.investor = self.investors;
                                self.search({from: 'wS - investor'});
                            });

                        } else {
                            self.search({from: 'ws - not investor'});
                        }

                        if (self.userInfo.username !== '_watson') {
                            window.localStorage.removeItem('wordStatQuery');
                        } else {
                            // console.log('~~ notions', self.$store.state.notions)
                        }

                    } else {
                        self.search({from: 'not Ws docType'});
                    }
                } else if (lastSearch && lastSearch.docType) {
                    self.handleLoadQuery(lastSearch.filter);
                    self.showSpam = !!lastSearch.filter.spam;
                    self.showDuplicate = lastSearch.filter.duplicate ? 'true' : '';
                    window.sessionStorage.removeItem('lastSearchProfile');
                } else {
                    self.search({from: 'not Ws and not Last search'});
                }
                window.addEventListener('storage', () => {
                    self.checkFav();
                    self.checkUserTags();
                    self.countFavs();
                });
                self.$store.dispatch('notions').then(() => {
                    self.getSavedQueries();
                    self.getPatternList();
                    self.countFavs();
                    self.getUserTagList();
                    self.resizeSelect('searchSortBy');
                    self.resizeSelect('searchPattern');
                    if (self.userSettings.skipTour) {
                        if (!self.userSettings.skipTour.search) {
                            self.startQuickTour();
                        }
                    } else {
                        self.startQuickTour();
                    }
                });
                self.widgetTabs = self.widgets.list ? _.map(self.widgets.list, (it, i) => i) : [];
            });
        },
        methods: {
            refreshTabs() {
                let activeTabFilter = _.get(this.activeTab, 'filter') || {};
                // console.log('activeTabFilter', activeTabFilter);
                // console.log('this.userInfo', this.userInfo);
                this.tabs = _.orderBy([{
                    id: 'News',
                    order: 10,
                    noResultLabel: 'news',
                    confirmNewTab: false,
                }, {
                    id: 'RuNews',
                    order: 15,
                    availableForRole: 'DEV',
                    hideIfNotAvailable: true,
                    confirmNewTab: false,
                }, {
                    id: 'Science',
                    order: 20,
                    noResultLabel: 'Science Pro articles',
                    availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                    hideIfNotAvailable: true,
                    confirmNewTab: false,
                },
                    /*
                                    {
                                        id: 'ScienceLens',
                                        order: 22,
                                        noResultLabel: 'Science Lens articles',
                                        availableForRole: 'DEV',
                                        showToRole: 'DEV',
                                        confirmNewTab: false,

                                    },
                    */
                    {
                        id: 'ScienceOpen',
                        order: 22,
                        noResultLabel: 'Science Open articles',
                        availableForRole: 'OPEN_SCIENCE_SEARCH',
                        hideIfNotAvailable: true,
                        confirmNewTab: false,
                    },
                    {
                        id: 'ScienceCrossref',
                        order: 26,
                        noResultLabel: 'Science Crossref articles',
                        availableForRole: 'CROSSREF_SCIENCE_SEARCH',
                        // availableForRole: 'DEV',
                        // showToRole: 'DEV',
                        confirmNewTab: false,
                    },
                    {
                        id: 'Patents',
                        noResultLabel: 'patents',
                        order: 30,
                        confirmNewTab: false,
                    }, {
                        id: 'Companies',
                        order: 40,
                        noResultLabel: 'companies',
                        availableForRole: "COMPANIES_SEARCH",
                        hideIfNotAvailable: true,
                        confirmNewTab: false,
                    }, {
                        id: 'MarketSize',
                        order: 50,
                        // title: 'Markets',
                        notDocuments: true,
                        noCustomFacets: false,
                        confirmNewTab: false,
                    }, {
                        id: 'Investment',
                        order: 60,
                        // title: 'Investment',
                        availableForRole: "COMPANIES_SEARCH",
                        // showToRole: 'COMPANIES_SEARCH',
                        hideIfNotAvailable: true,
                        notDocuments: true,
                        confirmNewTab: false,
                    }
                ].map(tab => {
                    let blocked = tab.availableForRole && !this.userInfo.roles.includes(tab.availableForRole);
                    let hidden = tab.showToRole && !this.userInfo.roles.includes(tab.showToRole)
                        || blocked && tab.hideIfNotAvailable;
                    return Object.assign(tab, {
                        uuid: uuid(),
                        title: this.$t('searchMode.source.' + tab.id),
                        blocked: blocked,
                        hidden: hidden,
                        // order: blocked ? tab.order + 10 : tab.order,
                        result: {
                            total: 0,
                            facets: [],
                            list: [],
                            loading: false
                        },
                        filter: {
                            offset: 0,
                            count: 20,
                            query: activeTabFilter.query || '',
                            types: [tab.id],
                            facets: {
                                calculate: true
                            }
                        }
                    });
                }), 'order');
                this.docTypes = _.map(this.tabs.filter(it => !it.notDocuments && !it.blocked), 'id');
                this.$nextTick(() => {
                    this.tabs.forEach(tab => {
                        tab.component = _.get(this.$refs, `searchList-${tab.id}.0`);
                    });
                    this.favoritesTab.component = _.get(this.$refs, 'searchList-Favorites');
                });
            },
            search(params = {}) {
                let refresh = params.refresh,
                    state = params.state,
                    promises = [],
                    isLoad = params.isLoad,
                    isAllTab = this.defaultActiveTab === 'All',
                    isNewsTab = this.defaultActiveTab === 'News',
                    isCrossrefTab = this.defaultActiveTab === 'ScienceCrossref',
                    isCompaniesTab = this.defaultActiveTab === 'Companies',
                    isFavoritesTab = this.defaultActiveTab === 'Favorites',
                    isMarketsTab = this.defaultActiveTab === 'MarketSize',
                    isInvestmentTab = this.defaultActiveTab === 'Investment';
                const self = this;
                this.noResult.show = false;
                let _global = self.globalFilter;
                // console.log('params from:', params.from);
                // console.log('globalFilter:', _global);
                this.tabs.forEach(it => {
                    it.filter.clusterFilter = _global.docType === it.id
                    && _global.clusterFilter.reportId ? _global.clusterFilter
                        : null;
                    if (self.structuredQuery) {
                        self.filterQuery = '';
                        it.filter.structuredQuery = self.structuredQuery;
                        it.filter.query = '';
                    } else {
                        it.filter.query = self.filterQuery;
                        it.filter.structuredQuery = null;
                    }
                    delete it.result.limit;
                })
                self.favoritesTab.filter.types = (self.favoritesTab.filter.types || []).length ? self.favoritesTab.filter.types
                    : _.map(self.tabs.filter(it => (!it.notDocuments && !it.hidden)), 'id');

                if (refresh) {
                    if (isFavoritesTab) {
                        this.favoritesTab._countRequest = null;
                    } else {
                        this.refreshTabs();
                        this._countRequest = null;
                    }
                }
                if (params.pattern === "reset") this.pattern = '';
                if (params.facets === "reset") self.$refs.searchFacets.clear();

                // let _countRequest = isFavoritesTab ? this.favoritesTab._countRequest : this._countRequest;
                // let updateTabTotals = !_countRequest || isLoad;
                // let firstRequest = _countRequest === undefined;

                // let activeTab = isFavoritesTab ? this.favoritesTab : isMarketsTab ? this.marketsTab : this.activeTab;
                let activeTab = isFavoritesTab ? this.favoritesTab
                    : isAllTab ? this.allTab
                        : this.activeTab;
                activeTab.filter.queryFields = self.queryFields.value;
                if (!isFavoritesTab) {
                    activeTab.filter.query = self.filterQuery;
                }


                let filter = _.merge({}, activeTab.filter, {
                    offset: state ? activeTab.filter.offset + activeTab.filter.count : 0,
                });
                if (filter.query) {
                    filter.query = filter.query.replace(/“|”/g, '"'); // ----- Mac “Hydrogen energy” - 201C и 201D
                    filter.query = filter.query.replace(/[\u25A0\u00A0\s]/g, ' ').replace(/\s\s+/g, ' '); // ----- Replace &nbsp; and double spaces -----
                }
                filter.suggestQuery = true;
                activeTab.filter.offset = filter.offset;

                if (isLoad) {
                    this._countRequest = null;
                }
                if (state && filter && filter.facets && filter.offset > 0) {
                    filter.facets.calculate = false;
                }

                let defaultCompaniesOrder = [
                    {field: 'year', type: 'DESC'},
                    {field: 'investmentTotal', type: 'DESC'}
                ];

                if (filter.clusterFilter && filter.clusterFilter.addScore) {
                    filter.clusterFilter.addScore = this.orderBy === "clusterRelevance";
                }
                if (this.orderBy === "date") {
                    if (isCompaniesTab) {
                        filter.order = defaultCompaniesOrder;
                    } else {
                        filter.order = [
                            {field: 'date', type: 'DESC'}
                        ];
                    }
                    filter.semantic = false;
                } else if (this.orderBy === "companyRank" && isCompaniesTab) {
                    filter.semantic = false;
                    filter.order = [
                        {field: 'companyRank', type: 'ASC'},
                        {field: 'investmentTotal', type: 'DESC'}
                    ];
                } else if (this.orderBy === "score") {
                    filter.semantic = false;
                    filter.order = [
                        {field: 'score', type: 'DESC'}
                    ];
                } else if (this.orderBy === "semantic") {
                    filter.semantic = true;
                    delete filter.order;
                } else {            // ==== "standard" sorting ====
                    if (isCompaniesTab) {
                        filter.order = filter.query ? null : defaultCompaniesOrder;
                    } else {
                        delete filter.order;
                    }
                    filter.semantic = false;
                }

                switch (self.showDuplicate) {
                    case 'only':
                        filter.duplicate = true;
                        break;
                    case 'all':
                        filter.duplicate = null;
                        break;
                    default:
                        filter.duplicate = false;
                        break;
                }

                if (isNewsTab) {
                    switch (self.showMarkets) {
                        case 'only':
                            filter.subfilters = [
                                {
                                    operator: 'AND',
                                    hostingGroups: {
                                        excludeFromSearch: true
                                    }
                                }
                            ];
                            break;
                        case 'all':
                            filter.subfilters = null;
                            break;
                        default:
                            filter.subfilters = [
                                {
                                    operator: 'AND_NOT',
                                    hostingGroups: {
                                        excludeFromSearch: true
                                    }
                                }
                            ];
                            break;
                    }
                }

                // filter.duplicate = self.showDuplicate ? null : false;
                filter.spam = self.showSPAM;

                if (this.userSettings.profileActive) {
                    filter.searchProfileIds = [this.userSettings.defaultProfile.id];
                } else {
                    delete filter.searchProfileIds;
                }
                filter.patternId = this.pattern ? this.pattern : null;
                filter.viewed = this.showViewedDocs;
                // filter.viewed = this.showViewedDocs ? null : this.showViewedDocs;

                if (self.isNativeQuery) { // --- if native Query is ON ---
                    filter.nativeQuery = {
                        value: filter.query
                    };
                    delete filter.query;
                } else {
                    if (filter.nativeQuery) {
                        delete filter.nativeQuery;
                    }
                }

                if (this.isDev) {
                    filter.explain = this.dev.explain;
                    filter.profile = this.dev.profile;
                }
                // Отладочная информация
                const applyDevData = !this.isDev ? _.identity : result => {
                    const elasticsearchRequest = result.elasticsearchRequest;
                    const profileResults = result.profileResults;
                    this.dev.elasticsearchRequest = elasticsearchRequest ? () => elasticsearchRequest : null;
                    this.dev.profileResults = profileResults ? () => profileResults : null;
                    delete result.elasticsearchRequest;
                    delete result.profileResults;
                    for (const it of result.list) {
                        const original = Object.freeze(_.clone(it));
                        it.original = () => original;
                        delete it.explanation;
                    }
                    return result;
                };

                self.saveGlobalParams();
                self.allTab.filter = _.cloneDeep(filter);
                (self.allTab.filter.facets || {}).requests = [];

                if (activeTab.id === 'MarketSize') {

                    // ************ MARKETS *************
                    filter.marketSize = {};
                    filter.marketSize.query = (this.marketDataType === 'context' || params.msSearchType === 'context') ? null : _.clone(filter.query);

                    filter.count = 40;
                    filter.order = [{
                        field: this.marketSortBy.field ? this.marketSortBy.field : 'month',
                        type: this.marketSortBy.dir ? this.marketSortBy.dir : 'DESC'
                    }, {
                        field: 'name',
                        type: 'ASC'
                    }]

                    console.log('MS search Filter:', filter, this.orderBy);
                    this.$services.documents.marketSize(filter).prepare().then(resp => {
                        const result = resp.data;
                        applyDevData(result);
                        if (state) {
                            if (result.list.length) {
                                activeTab.result.list = activeTab.result.list.concat(result.list);
                                activeTab.result = _.cloneDeep(activeTab.result);
                                state.loaded();
                            } else {
                                state.complete();
                            }
                        } else {
                            if (this.$refs.marketSize) {
                                this.$refs.marketSize.resetInfinite();
                            }
                            activeTab.result = result;
                        }
                        this.marketsTab.result = activeTab.result;

                        this.noResult.show = result.total === 0
                        // ----- No Result MS----->
                        if (this.noResult.show && this.marketDataType === 'direct') {
                            filter.marketSize.query = null;
                            this.$services.documents.marketSize(filter).prepare().then(resp => {
                                const result = resp.data;
                                if (result.total > 0) {
                                    // this.msResult.show = true;
                                    this.msResult.contextTotal = result.total;
                                } else {
                                    this.msResult.contextTotal = null;
                                }
                            });
                        } else {
                            this.msResult.contextTotal = null;
                        }
                        // >-----  no Result MS -----


                    }, err => {
                        if (err.response && err.response.data.message) {
                            this.$message({
                                type: 'error',
                                message: err.response.data.message
                            });
                        }
                    });

                } else if (activeTab.id === 'Investment') {

                    // ************ INVESTMENT *************


                    filter.investment = {
                        investorIds: Array.isArray(self.investor) ? _.map(self.investor, 'value') : [self.investor.value],
                        sum: {
                            min: self.invFilter.sumFrom ? self.$utils.fromFinHuman(self.invFilter.sumFrom) : null,
                            minBoundType: self.invFilter.sumFrom ? 'CLOSED' : null,
                            max: self.invFilter.sumTo ? self.$utils.fromFinHuman(self.invFilter.sumTo) : null,
                            maxBoundType: self.invFilter.sumTo ? 'CLOSED' : null,
                        }
                    };
                    filter.order = [{
                        field: self.investmentSortBy.field ? self.investmentSortBy.field : 'investment.date',
                        type: self.investmentSortBy.dir ? self.investmentSortBy.dir : 'DESC'
                    }]
                    if (self.investmentSortBy.field !== 'investment.date') {
                        filter.order.push({
                            field: 'investment.date',
                            type: 'DESC'
                        })
                    }

                    console.log('INVT search Filter:', filter, self.orderBy);

                    self.investmentTab.filter = _.cloneDeep(filter);
                    promises.push(this.$services.documents.investment(filter).prepare().then(resp => {
                        const result = resp.data;
                        // const result = resp.data && resp.data.total > 0 ? resp.data : self.fakeData;
                        applyDevData(result);
                        // console.log('INV resp:::::::::', result);
                        if (state) {
                            if (result.list.length) {
                                activeTab.result.list = activeTab.result.list.concat(result.list);
                                activeTab.result = Object.assign({}, activeTab.result);
                                state.loaded();
                            } else {
                                state.complete();
                            }
                        } else {
                            if (this.$refs.investment) {
                                this.$refs.investment.resetInfinite();
                            }
                            activeTab.result = result;
                        }

                        self.investmentTab.result = activeTab.result;
                        self.investmentTab.result.sumFormatted = self.$utils.toFinHuman(self.investmentTab.result.sum, 1);
                        // if(!resp.data.list.length && self.fakeData) self.$refs.investment.refresh();

                        self.noResult.show = result.total === 0
                        // ----- No Result INV { -----
                        if (this.noResult.show) {
                            this.getCounts(filter).then(resp => {
                                this.noResult.results = _.orderBy(resp && resp.types ? _.map(resp.types, (it, ind) => {
                                    let _tab = this.tabs.find(tab => tab.id === ind);
                                    return {
                                        order: _tab.order,
                                        name: ind,
                                        value: this.$utils.toFin(it),
                                        title: _tab && _tab.noResultLabel ? _tab.noResultLabel : ind
                                    }
                                }) : [], 'order');
                            })
                        } else {
                            this.noResult.results = [];
                        }
                        // -----  } no Result INV -----


                    }, err => {
                        if (err.response && err.response.data.message) {
                            this.$message({
                                type: 'error',
                                message: err.response.data.message
                            });
                        }
                    }));

                } else if (isAllTab) {
                    console.log('*** view 360 ***')
                } else {
                    // ************ OTHER *************
                    if (!state) {
                        if (isFavoritesTab) {
                            if (this.$refs.favoritesFacets) {
                                this.$refs.favoritesFacets.handleLoading(true);
                            }
                        } else {
                            if (this.$refs.searchFacets) {
                                this.$refs.searchFacets.handleLoading(true);
                            }
                        }
                    }
                    if (isCrossrefTab && (!filter.facets.requests || !filter.facets.requests.find(it => it.type === "HAS_DESCRIPTION"))) {
                        filter.facets.requests = filter.facets.requests || [];
                        filter.facets.requests.push(
                            {
                                isCommon: false,
                                type: "HAS_DESCRIPTION",
                                value: self.$refs.searchFacets.facetHasDescription.value || null

                            }
                        )
                    }

                    // console.log('search Filter:', filter, self.queryFields);
                    if (filter.facets) {
                        filter.facets.calculate = false;
                    }

                    promises.push(this.$services.documents.search(filter).prepare().then(resp => {
                        const result = resp.data;
                        applyDevData(result);
                        if (state) {
                            if (result.list.length) {
                                activeTab.result.list = activeTab.result.list.concat(result.list);
                                let activeTabResult = _.cloneDeep(activeTab.result);
                                if (isFavoritesTab) {
                                    activeTab.result = Object.assign({}, activeTabResult, _.pick(activeTabResult, ['limit', 'list', 'offset']));
                                } else {
                                    activeTab.result = activeTabResult;
                                }
                                activeTab.result.loading = false;

                                state.loaded();
                            } else {
                                state.complete();
                            }
                        } else {
                            if (activeTab.component) {
                                activeTab.component.resetInfinite();
                            }
                            if (isFavoritesTab) {
                                activeTab.result = Object.assign({}, activeTab.result, _.pick(result, ['limit', 'list', 'offset']));
                                this.getFavCounts();
                            } else {
                                // console.log('filter', filter.facets.requests);
                                // --- <EM> Marking of selected custom facets
                                (result.list || []).forEach(item => {
                                    let facets = item.facetValues || {};
                                    _.forEach(facets, (fac, type) => {
                                        if (filter.facets.requests) {
                                            let _filterItems = (filter.facets.requests.find(it => (it.type === 'CUSTOM' && it.facetId === type)) || {}).values || [];
                                            fac.items = fac.items.map(it => {
                                                it.name = _filterItems.includes(it.id) ? '<em type="' + _.upperFirst(type) + '">' + it.name + '</em>' : it.name;
                                                return it;
                                            })
                                        }
                                    })
                                });
                                activeTab.result = result;
                            }
                        }

                        this.noResult.show = result.total === 0;
                        // ----- No Result  { -----
                        if (this.noResult.show) {
                            this.getCounts(filter).then(resp => {
                                // console.log('getCounts', resp);
                                this.noResult.results = _.orderBy(resp && resp.types ? _.map(resp.types, (it, ind) => {
                                    let _tab = this.tabs.find(tab => tab.id === ind);
                                    return {
                                        order: _tab.order,
                                        name: ind,
                                        value: it,
                                        title: _tab && _tab.noResultLabel ? _tab.noResultLabel : ind
                                    }
                                }) : [], 'order');
                            })
                        } else {
                            this.noResult.results = [];
                        }
                        // -----  } No Result -----

                        this.querySuggestion = result.querySuggestion || {};
                        if (this.querySuggestion && this.querySuggestion.query) {
                            this.querySuggestion.formattedQuery = this.formatQueryNotions(this.querySuggestion.queryHighlight);
                            this.querySuggestion.formattedOrigin = this.formatQueryNotions(this.filterQuery);
                            setTimeout(() => {
                                this.querySuggestion.query = '';
                            }, 10000)
                        }

                        if (!isFavoritesTab && !isMarketsTab && this.$refs.predicateEditor) {
                            setTimeout(() => {
                                this.$refs.predicateEditor.hideAcDropdown();
                            }, 100);
                        }

                    }, err => {
                        if (err.response && err.response.data.message) {
                            this.$message({
                                type: 'error',
                                message: err.response.data.message
                            });
                        }
                    }));
                }
                self.searchFilter = activeTab.filter = _.cloneDeep(filter); // ---- saving query for SaveQuery

                // ********** Get Facets ***********
                Promise.all(promises).then(() => {
                    if (!isFavoritesTab && !isAllTab) {
                        let serviceType = isInvestmentTab ? 'investment' : isMarketsTab ? 'marketSize' : 'search';
                        if (filter.facets && !state) {

                            // console.log('// Facet facetFilter:', facetFilter);
                            if (!isAllTab) {
                                self.$refs.searchFacets.getFacets();
                            }
                            // console.log('Facets JSON: ', JSON.stringify(result.facets));

                            if (isFavoritesTab) {
                                if (this.$refs.favoritesFacets) {
                                    this.$refs.favoritesFacets.handleLoading(false);
                                }
                            } else {
                                if (this.$refs.searchFacets) {
                                    this.$refs.searchFacets.handleLoading(false);
                                }
                            }

                        }
                    }
                });
                if (isAllTab) {
                    if (self.$refs.searchFacets) {
                        self.$refs.searchFacets.startLoading = false;
                    }
                }

            },
            handleSearch(query){
                this.filterQuery = query;
            },
            getCounts(filter, updateTabTotals) {
                let query = Object.assign({}, filter, {types: this.docTypes});
                // console.log('getCount filter ::::: ', filter);
                if (updateTabTotals) {
                    this.tabs.forEach(tab => {
                        tab.result.loading = true
                    });
                }
                query.subfilters = [
                    {
                        operator: 'AND_NOT',
                        hostingGroups: {
                            excludeFromSearch: true
                        }
                    }
                ]
                return this.$services.documents.count(query).then(resp => resp.data);
            },
            saveLastFilter() {
                if (this.activeTab) {
                    let savedFilter = {
                        filter: this.searchFilter,
                        docType: this.activeTab.id,
                        isNativeQuery: this.isNativeQuery
                    };
                    // window.sessionStorage.setItem('lastSearchProfile', JSON.stringify(savedFilter));
                }
            },
            saveGlobalParams() {
                let self = this,
                    savedFilter = {
                        query: self.filterQuery,
                        isNativeQuery: self.isNativeQuery,
                        queryFields: self.queryFields,
                        from: 'search'
                    };
                window.sessionStorage.setItem('globalParams', JSON.stringify(savedFilter));
            },
            getPatternList() {
                let query = {
                    fullness: 'FULL',
                    count: 1881,
                    offset: 0,
                    order: [{
                        field: 'name',
                        type: 'ASC',
                    }]
                }
                this.$services.patterns.list(query).then((resp) => {
                    this.patternList = resp.data.list.map(it => {
                        let _out = it;
                        _out.show = true
                        return _out
                    })
                    // this.resizeInput('.pattern-control .el-input__inner', '500 14px Roboto');
                })


            },
            checkFav() {
                let fav = window.localStorage.getItem('changeFav')
                if (fav) {
                    // fav = JSON.parse(fav);
                    // let item = this.activeTab.result.list.find(it => it.id === fav.id);
                    // item.isInFavorite = fav.isInFavorite;
                    // this.activeTab.result = _.cloneDeep(this.activeTab.result)
                    // if (fav.isInFavorite) {
                    //     this.favoritesTab.result.total++;
                    // } else {
                    //     this.favoritesTab.result.total--;
                    // }
                    // console.log(fav, item);
                    localStorage.removeItem('changeFav');
                }
            },
            checkUserTags() {
                if (window.localStorage.getItem('changeUserTags')) {
                    this.getUserTagList();
                    this.countFavs();
                    localStorage.removeItem('changeUserTags');
                }
            },
            numberFormat(num) {
                return utils.numbers.format(num || 0);
            },
            notionName(id) {
                return (this.notionsDict.map[id] || {}).name || 'Undefined'
            },

            startQuickTour() {
                let self = this;
                if (self.widgetMode === 'all') {
                    self.$store.commit('QTChangeSection', {section: 'mainSearch'});
                    self.$store.commit('QTStart');
                    self.$refs.analytics.resetScroll()
                    let _skipTour = self.userSettings.skipTour || {};
                    _skipTour.searchView360 = true;
                    // console.log('uS', self.userSettings);
                    this.$services.userSettings.save({
                        skipTour: _skipTour
                    }).then(resp => {
                        console.log('userSettings: ', resp.data);
                    });
                } else {
                    self.resetTour();
                    self.modal.mode = 'TourStep';
                    self.modal.step = 'start';
                    self.modal.title = 'Добро пожаловать на платформу TeqViser!';
                    self.modal.class = 'quickTour';
                    self.modal.show = true;
                    self.tourStep = '0';
                    // window.localStorage.setItem('firstEnter', moment().format('lll'));
                    if (!(self.userSettings.skipTour && self.userSettings.skipTour.search)) {
                        this.$services.userSettings.save({
                            skipTour: {
                                search: true
                            }
                        }).then(resp => {
                            // console.log('userSettings: ', resp.data);
                        });
                    }
                }
            },
            finishQuickTour() {
                let self = this;
                self.modal.mode = 'TourStep';
                self.modal.step = 'finish';
                self.modal.title = 'Спасибо, что прошли этот тур с нами!';
                self.modal.class = 'quickTour';
                self.modal.show = true;
                self.$refs[`searchList-${self.defaultActiveTab}`].resetTour();
            },
            handleFinishQuickTour() {
                let self = this;
                self.resetTour();
                self.modal.show = false;
            },
            handleRestartQuickTour() {
                let self = this;
                self.resetTour();
                self.handleNextStep('start')
            },
            resetTour() {
                let self = this,
                    _tour = this.tour;
                _tour.curStep.index = 0;
                _tour.curStep.title = _tour.steps[0].title;
                _tour.curStep.text = _tour.steps[0].text;
                self.$refs.predicateEditor.resetTour();
                self.$refs.searchFacets.resetTour();
                self.$refs['searchList-News'][0].resetTour();
            },

            handleShowHelp() {
                this.$modal.dialog(Help, {
                    title: "Help",
                    params: {
                        style: 'background: #fff;'
                    },
                    buttons: [{
                        name: 'Close',
                        type: 'text',
                        handler: data => {
                            data.dialog.close();
                        }
                    }]
                }).catch(() => {
                });
            },
            userFeedback() {
                let _modal = this.modal;
                _modal.title = this.$t('label.feedback');
                _modal.mode = 'Feedback';
                _modal.show = !_modal.show;
            },
            resizeSelect(ref) {
                let self = this,
                    $element = document.getElementById(ref);
                let label = $element ? $element.value : null;
                if (label) {
                    self.$nextTick(() => {
                        label = $element.value;
                        $element.style.width = Math.ceil(label.length * 7.2) + 40 + 'px';
                        // $element.style.width = label.length + 2 + 'ch';
                    })
                }
            },
            handleSearchProfileChanged(status) {
                this.userSettings.profileActive = status;
                this.$refs.predicateEditor.apply();
            },
            handleSelectSelect(ref) {
                this.resizeSelect(ref);
                this.$refs.predicateEditor.apply();
            },
            handleToggleWidgetMode(mode) {
                let self = this;
                self.loading = true;
                setTimeout(() => {
                    self.$store.commit('toggleWidgetParam', {mode: mode});
                    self.loading = false;
                    self.$store.commit('toggleShowSwMenuBtn', mode === 'analytics')
                    self.$store.commit('toggleShowSwMenu', mode === 'analytics')
                    self.$nextTick(() => {
                        self.resizeSelect('searchSortBy');
                        self.resizeSelect('searchPattern');
                        if (self.defaultActiveTab === 'Investment' && mode === 'documents') {
                            self.$refs.investment.refresh();
                        }
                    })
                }, 500)
            },
            handleExperimentalMenu(item) {
                let self = this;
                switch (item) {
                    case 'showAllDocs':
                    case 'showNewDocs':
                    case 'showViewedDocs':
                        self.showViewedDocs = item === 'showAllDocs' ? null : (item === 'showViewedDocs');
                        self.toggleShowViewedDocs(self.showViewedDocs);
                        break;
                    case 'saveSearch':
                        self.handleSaveQueryBtn();
                        break;
                    case 'createCluster':
                        let minDocsForClustering = 100;
                        if (self.activeTab.result && self.activeTab.result.total < minDocsForClustering) {
                            this.$message({
                                type: 'error',
                                duration: 5000,
                                message: self.$t('errors.clusteringNotEnoughDocs') + minDocsForClustering
                            });
                        } else {
                            self.handleSaveQueryBtn('cluster');
                        }
                        break;
                    case 'structuredQuery':
                        this.toggleStructuredQuery()
                        break;
                    case 'chatBot':
                        this.toggleChatBot(true)
                        break;
                    case 'exportToExcel':
                        self.showExportMenu = true;
                        break;
                    case 'showSavedQueries':
                        self.handleShowSavedQueries();
                        break;
                    case 'showMoreLikeText':
                        self.showMoreLikeText = true;
                        break;
                    case 'handleShowSearchHistory':
                        self.getSearchHistory();
                        break;
                    case 'handleShowSPAM':
                        self.showSPAM = !self.showSPAM;
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'handleShowDuplicate':
                        self.showDuplicate = self.showDuplicate === 'all' ? '' : 'all';
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'handleShowOnlyDuplicate':
                        self.showDuplicate = self.showDuplicate === 'only' ? '' : 'only';
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'handleShowMarkets':
                        self.showMarkets = self.showMarkets === 'all' ? '' : 'all';
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'handleShowOnlyMarkets':
                        self.showMarkets = self.showMarkets === 'only' ? '' : 'only';
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'qField-all':
                        self.queryFields.value = [];
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'qField-title':
                        self.queryFields.value = ['title'];
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'qField-title-desc':
                        self.queryFields.value = ['title', 'description', 'authorKeywords'];
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'qField-companies':
                        self.queryFields.value = ['companies'];
                        self.$refs.predicateEditor.apply();
                        break;
                    case 'devExplain':
                        self.dev.explain = !self.dev.explain
                        break;
                    case 'devExplain-btn':
                        self.showJson(self.dev.elasticsearchRequest, 'Elasticsearch request');
                        break;
                    case 'devProfile':
                        self.dev.profile = !self.dev.profile
                        break;
                    case 'devProfile-btn':
                        self.showJson(self.dev.profileResults, 'Profile results');
                        break;
                }
            },
            handleTourStep(step) {
                this.tourStep = step ? step : '';
                if (step === 'finishTour') {
                    this.finishQuickTour();
                } else if (step === 'Favorites') {
                    this.tour.curStep.title = "Откройте Избранное";
                    this.tour.curStep.text = "в него автоматически попадают протегированные документы";
                }

            },
            handleNextStep(step = '') {
                let self = this;
                if (step === 'skip') {
                    self.handleTourStep('');
                } else if (step === 'start') {
                    self.modal.show = false;
                    self.tourStep = '1';
                } else if (self.tourStep === 'Favorites') {
                    self.finishQuickTour();
                } else {
                    let rez = self.$utils.tourNextStep({
                        step: step,
                        tour: self.tour
                    });
                    if (rez === 'nextTourBlock') {
                        self.handleTourStep('4');
                    } else if (rez === 'previousTourBlock') {
                        self.handleTourStep('2');
                    }
                }
            },
            handleChangeQueryFields(item) {
                this.queryFields = item;
                this.$refs.predicateEditor.apply();
            },
            handleSelectExperimental(key, keyPath) {
                console.log(key, keyPath);
            },
            handleSaveQueryBtn(destination = 'history') {
                this.savedQueries.destination = destination;
                if (this.activeTab.id !== 'Favorites') {
                    this.savedQueries.btnActive = !this.savedQueries.btnActive;
                    this.$refs.saveQueryTitle.$el.children[0].focus();
                    this.$refs.saveQueryTitle.focus();
                }
            },
            handleOpenCompanyCard(item) {
                window.sessionStorage.removeItem('companyCard');
                window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
            },
            handleSaveQueryInput(action) {
                let self = this,
                    _message = 'This process will take more than 10 minutes. Continue?',
                    _title = self.savedQueries.title;
                if (self.savedQueries.destination === 'cluster') {
                    _title = _title ? _title : 'Cluster ' + moment().format('DD.MM.YY hh:mm');
                    if(self.activeTab.result.total > 100000){
                        _message = 'TeqViser will cluster first 100K documents, because the number of documents is too big. ' + _message
                    }
                    if (_title && action === 'save') {
                        console.log('--- total: ', self.activeTab.result.total)
                        this.$confirm(_message, 'Warning', {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning'
                        }).then(() => {
                            let _filter = {
                                name: _title,
                                filter: self.searchFilter
                            };
                            self.savedQueries.saving = true;
                            self.$services.clusteringReport.save(_filter).then(resp => {
                                self.savedQueries.saving = false;
                                // console.log('Done', resp.data);
                            }, err => {
                                if (err.response && err.response.data.message) {
                                    this.$message({
                                        type: 'error',
                                        duration: 5000,
                                        message: err.response.data.message
                                    });
                                }
                            })
                        }).catch(() => {
                        });

                    }
                } else {
                    let _titles = _.map((self.savedQueries && self.savedQueries).list, 'title');
                    _title = _title ? _title : 'Saved Query';
                    _title = _titles.includes(_title) ? _title + ' (' + moment().format('DD.MM.YY hh:mm:ss') + ')' : _title;
                    if (_title && action === 'save') {
                        self.saveQuery(_title)
                    }

                }
                self.savedQueries.title = '';
                self.savedQueries.btnActive = false;
            },
            saveQuery(queryName) {
                // console.log('name:',queryName,'tab:', this.activeTab.id, 'filter:', this.searchFilter);
                // console.log('...', this.activeTab.filter.query)
                let self = this,
                    _date = moment().format('ll'),
                    _filter = {
                        creator: self.userInfo,
                        name: queryName || 'Search Query ' + _date.replace(/:/g, ''),
                        filter: self.searchFilter,
                        docType: self.activeTab.id
                    };
                self.savedQueries.saving = true;
                self.$services.savedQueries.save(_filter).prepare().then(resp => {
                    self.getSavedQueries();
                }).finally(() => {
                    self.savedQueries.saving = false;
                });
            },
            deleteQuery(id) {
                let _filter = {ids: [id]};
                this.$services.savedQueries.delete(_filter).prepare().then(resp => {
                    this.getSavedQueries();
                    console.log('Deleted:', resp);
                });
            },
            getSavedQueries() {
                let self = this,
                    _query = {
                        userIds: [this.userInfo.id],
                        fullness: "FULL",
                        offset: 0,
                        count: 100,
                        order: [{field: 'dateCreate', type: 'DESC'}]
                    };

                this.$services.savedQueries.list(_query).prepare().then(resp => {
                    let investors = {},
                        investorIds = [];
                    (resp.data.list || []).forEach(it => {
                        if (it.filter.investment && it.filter.investment.investorIds) {
                            investorIds = investorIds.concat(it.filter.investment.investorIds);
                        }
                    });
                    if (this.allowed.contains('COMPANIES_SEARCH')) {
                        self.$services.documents.search({
                            count: 100,
                            offset: 0,
                            types: ['Companies'],
                            ids: investorIds
                        }).then(invs => {
                            if (invs.data && invs.data.list) {
                                invs.data.list.forEach(it => {
                                    investors[it.id] = it.title;
                                })
                            }
                            this.savedQueries.list = _.map(resp.data.list, (it) => {
                                let _filter = it.filter,
                                    isNativeQuery = _filter.nativeQuery && _filter.nativeQuery.value,
                                    _query = isNativeQuery ? isNativeQuery : this.formatQueryNotions(it.filter.query),
                                    _pattern = this.patternList.find(it => it.id === _filter.patternId);

                                // if (_pattern && _pattern.name) _query += ` AND <div class="pattern">Pattern: ${_pattern['name']}</div>`
                                _filter.types = [it.docType];

                                return {
                                    id: it.id,
                                    title: it.name,
                                    query: _query,
                                    filter: _filter,
                                    investors: _filter.investment ? (_filter.investment.investorIds || []).map(inv => investors[inv]).join(', ') : null,
                                    docType: it.docType,
                                    isNativeQuery: isNativeQuery,
                                    pattern: _pattern ? _pattern.name : null
                                }
                            })
                            // console.log('Load List:', this.savedQueries.list);
                        })
                    }
                });
            },
            toggleStructuredQuery() {
                this.$store.commit('updateState', [{
                    path: 'showStructuredQuery',
                    data: !this.showStructuredQuery,
                }])
            },
            toggleChatBot(state) {
                this.$store.commit('updateState', [{
                    path: 'showChatBot',
                    data: state,
                }])
            },
            getSearchHistory() {
                let _query = {
                    count: 100,
                    offset: 0
                };

                this.$services.history.list(_query).prepare().then(resp => {
                    if (resp.data) {
                        this.searchHistory.list = _.map(resp.data.list, (it) => {
                            let formattedQuery = this.formatQueryNotions(it.query);
                            return {
                                id: it.id,
                                query: it.query,
                                formattedQuery: formattedQuery
                            }
                        })
                    }
                    this.showSearchHistory = true;
                    // console.log('~~~~~~~~~~ searchHistory:', this.searchHistory);
                });
            }
            ,
            handleDelHistory(item) {
                let _query = [item.id];
                this.$confirm('This will delete this query from history. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$services.history.delete(_query).prepare().then(resp => {
                        console.log('deleted:', resp.data);
                        this.getSearchHistory();
                    });
                }).catch(() => {
                });
            },
            marketSort(sortBy) {
                this.marketSortBy = sortBy;
                this.search()
            },
            investmentSort(sortBy) {
                this.investmentSortBy = sortBy;
                this.search()
            },
            formatQueryNotions(text, type = "html") {
                if (text) {
                    let regex = /<\d*>/g;
                    let _query = '';
                    let tags = text.match(regex);
                    let words = text.split(regex);
                    let notions = tags ? tags.map(it => {
                        // return '<span class="notion">' + it.replace(/[<>]/g, "") + '</span>';
                        return (type === 'html') ? '<span class="notion">' + this.notionName(it.replace(/[<>]/g, "")) + '</span> ' : '<' + this.notionName(it.replace(/[<>]/g, "")) + '>';
                    }) : '';
                    words.forEach((it, i) => {
                        _query += it + (notions[i] || '');
                    })
                    return _query;
                }
            },
            showSimilarDocs(item) {
                let _query = {
                    fullness: 'FULL',
                    offset: 0,
                    count: 40,
                    docId: {
                        id: item.id,
                        type: item.type || 'News'
                    }
                }
                this.$services.documents.similar(_query).prepare().then(resp => {
                    this.$modal.dialog(SearchListItem, {
                        title: "Similar documents",
                        params: {
                            style: 'background: #ecf5ff;'
                        },
                        ':value': {
                            list: resp.data.list,
                            from: 'docItem'
                        },
                        buttons: [{
                            name: 'Close',
                            type: 'text',
                            handler: data => {
                                data.dialog.close();
                            }
                        }]
                    }).catch(() => {
                    });
                })

            }
            ,
            showSimilarDocs2(item) {
                let _query = {
                    fullness: 'FULL',
                    offset: 0,
                    count: 40,
                    docId: {
                        id: item.id,
                        type: item.type || 'News'
                    }
                }
                this.$services.documents.similar(_query).prepare().then(resp => {
                    this.$modal.dialog(SearchList, {
                        title: "Similar documents",
                        params: {
                            style: 'background: #ecf5ff;'
                        },
                        ':value': resp.data,
                        buttons: [{
                            name: 'Close',
                            type: 'text',
                            handler: data => {
                                data.dialog.close();
                            }
                        }]
                    }).catch(() => {
                    });
                })

            },
            shiftMarketsYear(num) {
                this.shiftYear += num;
                this.yearsSlider = _.clone(this.shiftYear) - this.curYear;
                this.search();
            }
            ,
            slideMarketsYear() {
                this.shiftYear = _.clone(this.yearsSlider) + this.curYear;
                this.search();
            }
            ,
            formatSliderTooltip(val) {
                if (this.$refs.marketSize) {
                    val += this.curYear;
                    return val + ' - ' + (val + this.$refs.marketSize.maxYears - 1);
                }
            }
            ,
            toggleShowViewedDocs(isShow) {
                this.showViewedDocs = isShow;
                this.search({from: 'toggleShowViewedDocs'});
            }
            ,
            getUserTagList(item) {
                this.$services.tags.list({
                    count: 500,
                    offset: 0,
                    userIds: [this.userInfo.id]
                }).then(resp => {
                    if (resp && resp.data) {
                        this.fullUserTagList = resp.data.list;
                        this.refreshAssignTagsList(item);
                    }
                });
                if (this.defaultActiveTab === "Favorites") {
                    this.search({from: 'getUserTagList'});
                }
            }
            ,
            countFavs(params = {}) {
                let countsFilter = {
                    count: 20,
                    favorites: true,
                    offset: 0,
                    query: "",
                    types: (this.favoritesTab.filter && this.favoritesTab.filter.types) ? this.favoritesTab.filter.types : ["News", "Science", "Patents", "Companies"]
                };
                this.getCounts(countsFilter, false).then(data => {
                    let favoritesTabSetFilterData = (inFavorites = {}) => {
                        this.favoritesTab.filterData.types = this.docTypes.map(type => {
                            return {type: type, inFavorites: inFavorites[type] || 0}
                        });
                    };
                    this.favCounter = data;

                    for (let key in data.inFavorites) {
                        data.inFavorites[key] = parseInt(data.inFavorites[key]);
                    }
                    favoritesTabSetFilterData(data.inFavorites);
                    this.favoritesTab.result.total = _.values(data.inFavorites).reduce((sum, item) => sum + parseInt(item), 0);

                    this.refreshAssignTagsList();

                    if (this.defaultActiveTab === "Favorites" && params.recount) {
                        setTimeout(() => {
                            this.search();
                        }, 200);
                    }
                }); // -- } getCounts --
            },
            refreshAssignTagsList(item) {
                let self = this;
                this.assignedTagsList = _.orderBy(this.fullUserTagList.map(it => {
                    let _tag = _.find(self.favCounter.userTags, type => type.id === it.id) || {};
                    it.newTag = item ? it.id === item.id : false;
                    it.count = it.newTag ? 1 : _tag.count || 0;
                    it.popover = false;
                    return it;
                }), 'count').reverse();
            },
            reCountFavs() {
                setTimeout(() => {
                    this.countFavs({recount: true});
                }, 100);
            },
            getFavCounts() {
                let self = this,
                    query = (this.favoritesTab && this.favoritesTab.filter) ? this.favoritesTab.filter : {
                        count: 200,
                        favorites: true,
                        offset: 0,
                        query: '',
                        types: self.favoritesTab.filter.types
                    };

                // console.log('tabsssssss....', _tabs, self.tabs);
                self.$services.documents.count(query).then(resp => {
                    console.log('resp Favs search ^^^^', resp.data);
                    self.favCounter = resp.data;
                    self.refreshAssignTagsList();
                });
            },
            filterByTag(tagIds = null) {
                this.favoritesTab.filter.tagIds = tagIds;
                this.search();
            },
            loadLastSearch(lastSearch) {
                let self = this;
                self.defaultActiveTab = lastSearch.docType;
                self.activeTab.filter = lastSearch.filter;
                // self.filterQuery = last
            },
            handleSelectTab(key, filter, isLoad) {
                let self = this;
                let isFavoritesTab = key === 'Favorites';
                let is360Tab = key === 'All';
                let isMarketsTab = key === 'MarketSize';
                self.defaultActiveTab = key === 'back' ? self.prevActiveTab : key;
                self.searchPlaceholder = self.tabPlaceholder[key];
                if (filter && filter.patternId) self.pattern = _.clone(filter.patternId);
                else self.pattern = '';

                if (is360Tab) {
                    self.$nextTick(() => {
                        self.$store.commit('toggleWidgetParam', {mode: 'all'});
                        self.$store.commit('toggleWidgetParam', {mode: 'all'});
                        self.$store.commit('toggleShowSwMenu', true);
                        self.$store.commit('toggleShowSwMenuBtn', true);
                        self.$store.commit('activeMenuIndex', 0)
                        self.$store.commit('toggleSwMenu', 0);
                        if (self.$refs.analytics) self.$refs.analytics.resetScroll();
                        self.$refs.searchFacets.refresh();
                        self.$refs.searchFacets.startLoading = false;
                    })
                } else {
                    self.$store.commit('toggleWidgetParam', {mode: 'documents'});


                    // --- Очистка НЕ общих фасетов при переключении вкладки ---
                    if (self.$refs.searchFacets) {
                        self.$refs.searchFacets.resetNonCommonFacets()
                    }
                    if (self.activeTab && self.activeTab.filter.facets.requests) {
                        self.activeTab.filter.facets.requests = self.activeTab.filter.facets.requests.filter(it => it.isCommon);
                    }
                    //---

                    if (!isFavoritesTab) {
                        this.prevActiveTab = this.defaultActiveTab;
                    } else {
                        this.favoritesTab.filter.query = '';
                    }
                    if (key === 'Companies') {
                        self.orderByItems.push({label: 'label.companyRank', value: 'companyRank'})
                        self.lastOrderBy = self.orderBy;
                    } else {
                        self.orderByItems = self.orderByItems.filter(it => it.value !== 'companyRank');
                        self.orderBy = self.lastOrderBy;
                    }
                    let activeTab = isFavoritesTab ? this.favoritesTab : this.activeTab;
                    if (!activeTab.result.hasOwnProperty('limit') || isFavoritesTab) {
                        if (isFavoritesTab) {
                            this.favoritesTab._countRequest = null;
                        }
                        if (!isLoad && this.$refs.predicateEditor) {
                            this.$refs.predicateEditor.changePredicate();
                        }
                        activeTab.result.loading = true;
                        this.$nextTick(() => {
                            this.search({isLoad: filter !== undefined});
                        })
                    }
                    // this.resizeInput('.pattern-control .el-input__inner', '500 14px Roboto');
                    this.showExportMenu = false;
                    let notes = document.querySelectorAll('.el-popover.searchList-note');
                    notes.forEach(it => {
                        it.style.display = 'none';
                    });
                    self.$store.commit('toggleShowSwMenuBtn', false)
                    self.$store.commit('toggleShowSwMenu', false)
                    self.$nextTick(() => {
                        self.resizeSelect('searchSortBy');
                        self.resizeSelect('searchPattern');
                    })
                }
            },
            handleDeleteSavedQuery(title, id) {
                this.$confirm('This will delete this saved query. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deleteQuery(id);
                    this.$message({
                        type: 'success',
                        message: 'Delete completed'
                    });
                }).catch(() => {
                });
            },
            handleSelectionChangeFacet(selection) {
                let self = this;
                // console.log('---selection---', selection);
                _.set(self.activeTab, 'filter.facets.requests', selection.requests);
                let commonFacets = selection.requests.filter(it => it.isCommon);
                if (commonFacets.length || selection.requests.length === 0) {
                    let _tabFacets = {}
                    self.$refs.searchFacets.facetParam.forEach(tab => {
                        _tabFacets[tab.id] = {};
                        _.forEach(tab.items, (it, i) => {
                            let ind = it.altName ? it.altName : it.type;
                            _tabFacets[tab.id][ind] = {
                                prefix: it.prefix
                            }
                        })

                    })

                    self.tabs.filter(it => ![self.activeTab.id].includes(it.id)).forEach(tab => {
                        delete tab.result.limit;
                        _.set(tab, 'filter.facets.requests', _.cloneDeep(commonFacets).map(f => {
                            let _curTab = _tabFacets[tab.id],
                                _idx = f.type.replace(/\w+_/g, '');
                            // console.log('tab', _curTab);
                            f.type = _curTab[_idx] && _curTab[_idx].prefix ? _curTab[_idx].prefix + '_' + _idx : _idx;
                            return f;
                        }));
                    });
                }

            }
            ,
            handleToggleFavorites(doc) {
                let docTab = this.tabs.find(tab => tab.id === doc.type);
                let incrementToggle = (val) => {
                    this.favoritesTab.filterData.types.forEach(item => {
                        if (item.type === doc.type) {
                            if (val) {
                                item.inFavorites = parseInt(item.inFavorites) + 1;
                            } else {
                                item.inFavorites = parseInt(item.inFavorites) - 1;
                            }
                        }
                    });
                };
                // docTab.result.list.forEach(item => {
                //     if (item.id === doc.id) {
                //         item.isInFavorite = doc.isInFavorite;
                //     }
                // });

                if (doc.isInFavorite) {
                    incrementToggle(true);
                    this.favoritesTab.result.total++;
                } else {
                    incrementToggle(false);
                    this.favoritesTab.result.total--;
                }

                // this.favoritesTab.filterData = _.cloneDeep(this.favoritesTab.filterData);
                // docTab.result = _.cloneDeep(docTab.result);
            },
            handleCloseMoreLikeText() {
                this.showMoreLikeText = false;
                this.reCountFavs();
                this.getUserTagList();
            },
            handleCloseMoreLikeThis() {
                this.showMoreLikeThis = false;
                this.reCountFavs();
                this.getUserTagList();
            },
            handleShowMoreLikeThis(item) {
                this.moreLikeThisItem = _.cloneDeep(item);
                this.showMoreLikeThis = true;
            },
            handleAdvancedSearch(data) {
                let self = this,
                    _advs = [];
                if (data) {
                    _.each(data, it => {
                        if (it.value) {
                            self.activeTab.filter[it.field] = (['ids', 'urls'].includes(it.field)) ? [it.value] : it.value;
                            _advs.push(it.field);
                        } else {
                            delete self.activeTab.filter[it.field];
                        }
                    });
                    if (_advs.length > 0) {
                        self.activeTab.filter.advancedSearchParams = _advs;
                    } else {
                        delete self.activeTab.filter.advancedSearchParams;
                    }
                }
            },
            handleShowSavedQueries() {
                this.showSavedQueries = true;
            },
            handleCloseSavedQueries() {
                this.$refs.savedQueries.closeDrawer()
            },
            handleCloseSearchHistory() {
                this.$refs.searchHistory.closeDrawer()
            },
            handleResetPopovers() {
                this.tabs = this.tabs.map(it => {
                    it.confirmNewTab = false;
                    return it;
                });
            },
            handleLoadHistoryQuery(item) {
                this.filterQuery = item.query;
                this.handleCloseSearchHistory();
                this.search({from: 'handleLoadHistoryQuery'});
            },
            handleOpenSourceBtn(tab) {
                let self = this;
                self.tabs = self.tabs.map(it => {
                    it.confirmNewTab = it.id === tab.id;
                    return it;
                });
            },
            handleOpenSource(tab) {
                let self = this,
                    query = _.cloneDeep(self.searchFilter);
                query.docType = tab.id;
                query.facets.requests = (query.facets.requests || []).filter(it => {
                    return ['YEAR', 'CUSTOM'].includes(it.type)
                })
                delete query.types;
                tab.confirmNewTab = false;
                // console.log('QQQ', query);
                self.$utils.openSearch(query);
            },
            handleLoadQuery(filter) {
                if (filter && filter.types) {
                    filter = _.cloneDeep(filter);
                    let self = this,
                        promises = [],
                        isNativeQuery = !!(filter.nativeQuery && filter.nativeQuery.value),
                        filterQuery = isNativeQuery ? filter.nativeQuery.value : filter.query,
                        loadTab = self.tabs.find(it => filter.types.includes(it.id));
                    _.set(loadTab, 'filter', _.merge(filter, {
                        facets: {
                            "calculate": true,
                        },
                        count: 40
                    }));
                    promises.push(self.$services.patterns.list({count: 1881, offset: 0}));
                    if (filter.investment && filter.investment.investorIds) {
                        promises.push(self.$services.documents.search({
                            count: filter.investment.investorIds.length,
                            offset: 0,
                            types: ['Companies'],
                            ids: filter.investment.investorIds
                        }))
                    }
                    Promise.all(promises).then((resp) => {
                        let patternIds = _.map(resp[0].data.list, 'id');
                        filter.patternId = patternIds.includes(filter.patternId) ? filter.patternId : null;
                        self.isNativeQuery = isNativeQuery;
                        self.filterQuery = filterQuery;
                        self.tabs.forEach(it => {
                            it.filter.query = filterQuery;
                        });
                        if (filter.structuredQuery) {
                            this.$store.commit('updateState', [{
                                path: 'structuredQuery',
                                data: filter.structuredQuery,
                            }, {
                                path: 'showStructuredQuery',
                                data: true,
                            }]);
                            this.$nextTick(() => {
                                this.$refs.structuredQuery.loadStructuredQuery()
                                this.$nextTick(() => {
                                    this.$refs.structuredQuery.apply()
                                })
                            })
                        }
                        if (filter.investment && filter.investment.investorIds) {
                            self.investors = (resp[1].data.list || []).map(it => {
                                let _out = {
                                    label: it.title,
                                    logo: it.icon && it.icon.url ? it.icon.url : 'noLogo2.png',
                                    id: it.id,
                                    value: it.id
                                }
                                return _out;
                            })
                            self.investor = self.investors;
                            if (filter.investment.sum.min) {
                                self.invFilter.sumFrom = self.$utils.toFinHuman(filter.investment.sum.min).number;
                            }
                            if (filter.investment.sum.max) {
                                self.invFilter.sumTo = self.$utils.toFinHuman(filter.investment.sum.max).number;
                            }
                        }
                        loadTab.advSearchParams = loadTab.advSearchParams ? loadTab.advSearchParams.map(it => {
                            let _it = it;
                            _it.value = Array.isArray(filter[it.field]) ? filter[it.field][0] : filter[it.field]
                            return _it;
                        }) : [];
                        if (self.activeTab && self.activeTab.result) delete self.activeTab.result.limit;
                        delete loadTab.result.limit;
                        self.handleSelectTab(loadTab.id, filter, true);
                        self.handleCloseSavedQueries();
                        // console.log('load Tab----------', loadTab);
                    })
                }
            },
            handleChangePattern() {
                this.search({});
            },
            handleQuerySuggestion(query) {
                if (query) {
                    this.filterQuery = query;
                    this.search({from: 'handleQuerySuggestion'});
                }
            },
            handleSwithchToContextMS() {
                this.marketDataType = 'context';
                this.search({from: 'handleSwithchToContextMS'});
            },
            handleIsNativeQuery() {
                let self = this;
                self.isNativeQuery = !self.isNativeQuery;
            },
            handleSelectDoc(item) {
                let self = this,
                    activeTab = self.defaultActiveTab === 'Favorite' ? self.favoritesTab : self.activeTab,
                    _item = activeTab.result.list.find(it => it.id === item.id);
                (_item || {}).checked = item.checked;
                // console.log('---------selected', item, activeTab.result);
                // let _count = this.result.list.filter(it => it.checked).length;
                // this.exportCheckedCount = _count;
                // this.exportCheckedDisabled = _count < 1;
            },

            handleExportDocs(params) {
                let query = {},
                    self = this,
                    activeTab = self.defaultActiveTab === 'Favorite' ? self.favoritesTab : self.activeTab,
                    fileName = 'Teqviser_Documents.xlsx',
                    docType = self.defaultActiveTab,
                    serviceType = self.type === "Favorites" ? 'favoritesExport' : 'documentExport';
                this.exportLoading = true;
                if (params && params.all) {
                    let filter = _.cloneDeep(this.activeTab.filter);
                    query = {docIds: null, filter: filter};
                    fileName = 'Teqviser_' + docType + '_all(2k).xlsx';
                } else {
                    let _data = activeTab.result.list.filter(item => item.checked),
                        ids = _.map(_data, (it) => {
                            let _type = self.defaultActiveTab === "Investment" ? "Investment" : it.type
                            return {id: it.id, type: _type}
                        })
                    query = {docIds: ids, filter: null};
                    fileName = 'Teqviser_' + docType + '_selected(' + ids.length + ').xlsx';
                }
                axios({
                    url: this.$store.state.apiUrl + '/documents/' + serviceType,
                    method: 'POST',
                    data: JSON.stringify(query),
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json;charset=UTF-8'}
                }).then((resp) => {
                    // console.log('Checked query:', query, this.filter);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(resp.data);
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    this.exportLoading = false;

                })

            },
            handleContextMenu(event) {
                this.$utils.wikiTranslateContext(this, event);
            },
            resizeInput(selector, font) {
                setTimeout(() => {
                    let _input = document.querySelector(selector);
                    if (_input) {
                        let _strLen = this.displayTextWidth(_input.value, font);
                        // _input.style.width = _strLen + 33 + 'px';
                    }
                }, 150)
            },
            handleEnterInvestor() {
                if (this.investorLast.length > this.investor.length) {
                    this.investor = _.clone(this.investorLast);
                    this.search();
                } else {
                    this.investorLast = _.clone(this.investor);
                }
            },
            handleUpdateInvestorLast() {
                setTimeout(() => {
                    this.investorLast = _.clone(this.investor);
                }, 200);
            },
            filterInvestors(query) {
                if (query && query.length > 1) {
                    this.investorsLoading = true;
                    this.invFilter.investorFilterQuery = '';
                    let _query = {
                        "offset": 0,
                        "count": 30,
                        "titleQuery": query + "*",
                        "companyIsInvestor": true,
                        "types": ["Companies"],
                        "facets": {"calculate": false},
                        "order": [{field: 'title', type: 'ASC'}],
                    };
                    this.$services.documents.search(_query).then(resp => {
                        this.invFilter.investorFilterQuery = query;
                        this.investorsLoading = false;
                        this.investors = (resp.data.list || []).map(it => {
                            return {
                                label: it.title,
                                value: it.id,
                                logo: it.icon && it.icon.url ? it.icon.url : 'noLogo2.png'
                            }
                        });
                    })
                    // console.log('this.investors', this.investors);
                } else {
                    this.investors = [];
                }
            },
            handleToFinFormat(item, field) {
                let _val = item.replace(/\s/g, '');
                if (!_val.match(/[kmb,.]/i)) {
                    this.invFilter[field] = this.$utils.toFin(_val, 0);
                }
            },
            handleKeydownInvSum(e) {
                if (e.key === 'Enter') {
                    this.$refs.predicateEditor.apply();
                }
            },
            displayTextWidth(text, font) {
                let canvas = this.displayTextWidth.canvas || (this.displayTextWidth.canvas = document.createElement("canvas"));
                let context = canvas.getContext("2d");
                context.font = font;
                let metrics = context.measureText(text);
                return metrics.width;
            },
            changeLang() {
                let self = this;
                self.refreshTabs();
                // this.searchPlaceholder = _.clone(this.searchPlaceholder);
                self.$refs.predicateEditor.apply();
                self.resizeSelect('searchSortBy');
                self.resizeSelect('searchPattern');
                self.$refs.predicateEditor.resizeSelect('queryFieldsSelector');
                if (self.$refs.marketSize) {
                    self.$refs.marketSize.refreshMarketSchema();
                }
            },
            showJson(json, title) {
                this.$modal.dialog(JsonViewer, {
                    title,
                    size: 'center-wide',
                    params: {
                        value: json,
                        open: 3,
                        fragments: [{name: 'query', path: 'source.query', open: 4}, {
                            name: 'aggrs',
                            path: 'source.aggregations',
                            open: 2
                        }, {name: 'sort', path: 'source.sort', open: 1}]
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    .fst-Search {
        min-width: 1280px;
        height: 100%;

        .header {
            &-center {
                display: flex;

                .fst-PredicateEditor {
                    flex: 1;
                    display: flex;
                    //margin-right: 10px;
                }
            }
        }

        .save-input {
            position: absolute;
            top: 52px;
            right: -80px;
            background: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            box-shadow: $iq-shadow;
            width: 250px;
            display: flex;
            align-items: center;

            .el-input .el-input__inner {
                padding-right: 15px;
            }

            .control-btns {
                display: flex;
                margin-left: 7px;

                > button {
                    font-size: 18px;
                    padding: 5px;
                    margin-left: 2px;
                    cursor: pointer;
                    border: none;
                    background: #fff;

                    &.save-btn {
                        color: $iq-color-blue;
                    }

                    &.clear-btn {
                        color: $iq-color-grey-blue;
                    }

                    &[disabled] {
                        color: $iq-color-grey-light;
                        cursor: not-allowed;
                    }
                }
            }
        }

        &.showStructuredQuery {
            .save-input {
                top: auto;
                right: -15px;
                bottom: -8px;
            }
        }


        .fst-PredicateEditor {
            .control-group {

                .nativeSearch {
                    color: $iq-color-grey-light;
                    position: absolute;
                    font-weight: 400;
                    cursor: pointer;
                    transition: all .25s ease;
                    top: 15px;
                    right: 180px;
                    white-space: nowrap;
                    background: #fff;

                    span {
                        font-size: 14px;
                    }

                    &:hover {
                        //color: #fff;
                    }

                    .el-switch {
                        .el-switch__label {
                            color: #B8C4E5;
                        }

                        .el-switch__core {
                            height: 16px;

                            &:after {
                                width: 12px;
                                height: 12px;
                            }
                        }

                        &.is-checked {
                            .el-switch__label {
                                color: #7D8BB2;
                            }

                            .el-switch__core::after {
                                margin-left: -13px;
                            }
                        }
                    }
                }

            }
        }

        .pattern-control.iq-select-like .el-select {
            //min-width: 113px;
        }

        .markets-year-control {
            padding-right: 10px;

            .el-button {
                padding: 1px 5px;
            }

            .label {
                color: $iq-color-grey-blue;
                font-weight: 500;
                margin: 0 5px;
                display: inline-flex;
            }
        }

        .content {
            .center-content {
                display: flex;
                flex-direction: column;

                .tab-nav {
                    //width: calc(100vw - 730px);
                    background: $iq-color-white;
                    position: relative;

                    .el-menu {
                        border-bottom: none;
                        display: flex;
                        //                        justify-content: space-between;
                        align-items: center;
                        padding: 0 20px;
                        gap: 20px;

                        &:before, &:after {
                            content: none;
                        }

                        &-item {
                            font-size: 14px;
                            height: 52px;
                            line-height: normal;
                            display: flex;
                            align-items: center;
                            font-weight: bold;
                            padding: 0;
                            //margin: 0 26px 0 0;
                            user-select: none;

                            .el-tag {
                                .loading-icon {
                                    display: none;
                                    margin: 0;
                                }
                            }

                            &.hide {
                                display: none;
                            }

                            &.is-disabled {
                                order: 2;
                            }

                            &.Favorites {
                                //margin-right: 35px;
                                //flex-basis: calc(100% - 895px);
                                display: flex;
                                margin-left: auto;
                                justify-content: flex-end;
                                order: 10;
                                margin-right: 0;

                                &.is-active {
                                    color: $iq-color-orange !important;
                                    border-bottom-color: $iq-color-orange !important;

                                    .el-tag {
                                        background: #FCE8D7;
                                        border-color: #FCE8D7;
                                        color: $iq-color-orange;
                                    }
                                }
                            }

                            i {
                                color: $iq-color-text-primary;
                                margin-right: 5px;
                            }

                            .icon {
                                margin: -2px 5px 0 0;
                            }

                            .name {
                                margin-right: 5px;
                                line-height: 14px;
                            }

                            .sorting {
                                display: flex;
                                align-items: center;

                                label {
                                    display: inline-block;
                                    margin-right: 3px;
                                }

                                .el-input__inner {
                                    padding: 0 2px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    border: none;
                                    color: $iq-color-blue;
                                    width: auto;
                                    line-height: 1;
                                }

                                .el-input__suffix {
                                    top: 1px;

                                    i {
                                        color: $iq-color-blue;
                                        font-weight: 900;
                                    }
                                }
                            }

                            &.loading .el-tag {
                                > span {
                                    display: none;
                                }

                                .loading-icon {
                                    display: inline-block;
                                    color: $iq-color-grey-light;
                                }
                            }
                        }

                        &.right-menu {
                            .el-menu-item {
                                border-color: $iq-color-white !important;
                            }
                        }
                    }

                    .widgetModeTabs {
                        position: absolute;
                        display: flex;
                        top: 4px;
                        right: 170px;
                        z-index: 100;

                        .tab {
                            background: #fff;
                            display: flex;
                            align-items: center;
                            padding: 0 15px;
                            height: 47px;
                            cursor: pointer;
                            border: $border;
                            transition: all .15s ease;
                            /*font-variant: all-small-caps;*/
                            font-weight: 700;
                            font-size: 15px;
                            color: $iq-tag-color-12;

                            & + .tab {
                                border-left: none;
                            }

                            &.active {
                                background: $iq-color-blue-lighter;
                                color: $iq-color-text-primary;
                                border-bottom-color: transparent;
                            }
                        }

                        .label {
                            padding-left: 4px;
                        }

                        svg {
                            height: 18px;
                            transform: translate(0px, -1px);
                        }
                    }

                }

                .tab-content {
                    flex: 1;
                    overflow: hidden;
                    background: $iq-color-blue-lighter;
                    padding: 5px 20px 0;
                    border-top: 1px solid $iq-border-color-base;
                }

                .total-docs {
                    text-align: right;
                    width: 850px;
                    font-variant: all-small-caps;
                    font-size: 13px;
                    font-weight: 500;
                    padding-top: 2px;
                    border-top: 1px solid $iq-border-color-base;
                }
            }

            .querySuggestion,
            .content-control {
                width: 850px;
                transition: none !important;
            }

            .content-control {
                display: flex;
                align-items: center;
                justify-content: space-between;
                /*margin-bottom: 9px;*/
                height: 40px;
                font-size: 14px;
                margin-bottom: 5px;


                label {
                    color: $iq-color-grey-light;
                    font-weight: 500;
                    line-height: 1;
                    position: relative;
                    top: 1px;
                }

                .widgetModeTabs {
                    display: flex;
                    margin-right: 20px;
                    position: relative;
                    top: 2px;
                    z-index: 100;

                    > div {
                        background: $iq-border-color-base;
                        padding: 9px 20px 11px;
                        cursor: pointer;
                        border: $border;
                        transition: all .15s ease;
                        font-variant: all-small-caps;
                        font-weight: 500;
                        color: fade_out($iq-color-primary, .4);
                        border-bottom: none;

                        &.active {
                            background: #fff;
                            color: $iq-color-text-primary;
                        }
                    }
                }

                .sort-control {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    height: 32px;
                    margin-right: auto;

                    > label {
                        margin-right: 15px;
                    }

                    .el-radio-group {
                        label {
                            span {
                                color: $iq-color-grey-light;
                                font-weight: 700;
                                background: transparent;
                                border: none;
                                box-shadow: none;
                                font-size: 14px;
                                padding: 0;
                                line-height: 1;
                                margin-right: 10px;
                            }

                            &.is-active {
                                span {
                                    color: $iq-color-blue;
                                }
                            }
                        }
                    }
                }

                .iq-select-like {
                    min-width: 112px;
                    margin-right: -8px;
                }
            }

            &.fullScreen {
                .fst-SearchList .list-item-container,
                .querySuggestion,
                .content-control {
                    //width: calc(100vw - 55px);
                }

                .fst-MarketSize,
                .fst-Investment {
                    max-width: calc(100vw - 98px);
                }

            }
        }

        .saved-queries {
            .item {
                padding: 10px 20px 15px;
                background: #fff;
                border-radius: 5px;
                border: 1px solid $iq-border-color-base;
                margin-bottom: 15px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .el-tag {
                    font-variant: all-small-caps;
                    color: #fff;
                    background: $iq-color-blue;
                    border-color: $iq-color-blue;
                }
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 7px;

                .title {
                    color: $iq-text-color-highlight;
                    font-size: 20px;
                    font-weight: 900;
                    line-height: 30px;
                    cursor: pointer;

                    &:hover {
                        color: $iq-color-primary;
                    }
                }

                .delete {
                    color: #B8C4E5;
                    font-weight: 500;
                    font-variant: all-small-caps;
                    font-size: 14px;
                    cursor: pointer;
                    transition: all .25s ease;

                    &:hover {
                        color: $iq-color-danger;
                    }
                }
            }

            .attrs, .investors {
                color: $iq-color-grey-light;
                font-weight: 500;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                > div {
                    display: flex;
                    align-items: center;

                    > div + div {
                        margin-left: 30px;
                    }

                    span {
                        color: $iq-color-blue;
                    }
                }
            }

            .investors {
                > div {
                    align-items: flex-start;

                    span {
                        display: inline-block;
                        margin-left: 4px;
                    }
                }
            }

            .query {
                border: 1px solid $iq-color-blue-light;
                border-radius: 5px;
                min-height: 50px;
                max-height: 150px;
                overflow-y: auto;
                line-height: 30px;
                padding: 9px 15px;
                box-sizing: border-box;
                background: #fff;

            }

            .delHistory {
                position: absolute;
                top: 13px;
                right: 10px;
                background: #fff;
                padding: 5px;
            }

            .notion, .pattern {
                @include iq-button-sm;
                font-variant: all-small-caps;
                font-size: 14px;
                line-height: 1;
                white-space: nowrap;
            }

            .notion {
                margin-right: 3px;
            }

            .pattern {
                background: $iq-color-blue;
                color: #fff;
                margin-top: 10px;
            }

            .structuredQueryTab {
                width: 100%;

                td {
                    padding: 7px 3px;
                    vertical-align: top;

                    &:last-child {
                        width: 55%;
                    }

                    > div {
                        padding: 4px 8px;
                        border: $border;
                        border-radius: 4px;
                        display: flex;
                        gap: 6px;
                        flex-wrap: wrap;

                        &:empty {
                            display: none;
                        }

                        span {
                            background: $iq-color-blue-light;
                            padding: 2px 4px;
                            border-radius: 3px;
                            margin: -2px 0;
                        }
                    }
                }
            }
        }

        .year-slider-block {
            padding-right: 10px;

            > div {
                display: flex;
                align-items: center;
            }

            label {
                font-weight: 500;
                color: $iq-color-grey-blue;
                display: inline-block;
                margin-right: 15px;
            }

            .el-slider {
                width: 200px;
            }
        }

        .querySuggestion {
            //background: #fff;
            //border: 1px solid $iq-border-color-base;
            //border-radius: 0 0 5px 5px;
            //padding: 12px 15px;
            //margin: -15px 0 12px;
            padding: 0 0 15px 0;
            box-sizing: border-box;

            .notion {
                @include iq-button-sm;
                line-height: 1;
                white-space: nowrap;
                position: relative;
                top: -1px;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            .origin,
            .suggested {
                overflow: auto;
            }

            .origin {
                margin-bottom: 6px;
                max-height: 37px;

                span {
                    //font-weight: 600;
                    color: $iq-color-text-primary;
                }
            }

            .suggested {
                font-size: 13px;
                max-height: 31px;

                em {
                    font-style: normal;
                    font-weight: 600;
                    //text-decoration: underline;
                }
            }
        }

        .noResult {
            background: #fff;
            border-radius: 5px;
            box-sizing: border-box;
            width: 850px;
            flex-direction: row;
            justify-content: space-between;
            height: auto;
            min-height: 30vh;
            padding: 40px 60px;
            text-align: left;
            border: 1px solid $iq-border-color-base;

            .description {
                margin: 0 60px 0 0;
            }

            img {
                max-height: 25%;
            }
        }

        .drawer-container {
            .noResult {
                width: 100%;
            }
        }

        .prePredicate {
            position: relative;
            flex-basis: 280px;

            > div {
                max-height: 82px;
                overflow-y: auto;
                overflow-x: hidden;
                border: 1px solid $iq-border-color-base;
                border-radius: 5px;
                outline: none !important;
                margin-right: 15px;
            }

            .clearPP {
                position: absolute;
                top: calc(50% - 16px);
                right: 25px;
                padding: 5px;
                cursor: pointer;
                font-size: 19px;
                color: $iq-color-grey-light;
                transition: all .2s ease;

                &:hover {
                    color: $iq-color-blue;
                }
            }

            .el-select .el-input--small .el-input__inner {
                font-weight: 400;
            }
        }

        .investorSelect {
            width: 100%;

            .el-input {
                display: flex;
                align-items: center;
                min-height: 48px;
                line-height: 30px;
                font-size: 15px;
                outline: none !important;

                input {
                    padding: 20px 15px;
                    border: none;
                }
            }

            .el-input__prefix {
                display: flex;
                align-items: center;
            }
        }

        .sumFilter {
            padding: 15px;
            border-bottom: 1px solid $iq-border-color-base;
            border-right: 1px solid $iq-border-color-base;

            .label {
                font-weight: 700;
                font-size: 17px;
            }

            .filter-block {
                display: flex;
                align-items: center;
                padding: 10px 8px 0 0;
            }

            & + .fst-SearchFacets {
                height: calc(100% - 120px);
            }
        }

        .clearSuggestion {
            padding: 5px;
            cursor: pointer;
            float: right;
            color: $iq-color-grey-light;
            height: 30px;
            margin: -5px 0px 0 0;
        }
    }

    .markInvestor {
        background: none !important;
        font-weight: 700;
    }

    .experimentalMenu {
        &.el-menu--horizontal {
            border-radius: 5px;
            border: 1px solid $iq-border-color-base;

            .el-menu {
                padding: 7px;
                margin-top: 0;
                border-radius: 5px;

                .el-submenu__title {
                    font-size: 13px;
                    line-height: 27px;
                    height: 27px;
                    padding: 0;
                    color: $iq-color-text-primary;
                    background: transparent;

                    .el-submenu__icon-arrow {
                        right: 5px;
                    }
                }
            }
        }

        &.dark {
            &.el-menu--horizontal {
                border: 1px solid transparent;

                .el-menu {
                    background: $iq-dark-theme-color-border;
                    color: $iq-dark-theme-light;

                    .el-menu-item,
                    .el-submenu__title {
                        background: $iq-dark-theme-color-border;
                        color: $iq-dark-theme-light;
                    }

                    .el-submenu.is-active > .el-submenu__title {
                        color: $iq-dark-theme-light;
                    }
                }
            }
        }
    }

    .openInNewTab {
        word-break: normal !important;
        min-width: 0 !important;
        width: auto;
    }

    @media screen and (max-width: 1700px) {
        .fst-Search {
            .content {
                .center-content {
                    .tab-nav {
                        .el-menu {
                            gap: 18px;
                            padding-right: 7px;
                        }

                        .el-menu-item {
                            font-size: 13.5;
                        }
                    }
                }
            }

        }
    }

</style>

<style lang="scss" scoped>
    .quasiContext {
        margin: -5px -12px;

        > div {
            cursor: pointer;
            transition: all .1s ease;
            padding: 5px 12px;

            &:hover {
                background: $iq-color-blue-light-ultra;
            }
        }
    }

    .drawer-container > {
        .header {
            position: relative;
        }

        .body {
            background: $iq-color-blue-light-ultra;
        }
    }

    .el-drawer__body > * {
        outline: none;
    }

    .investorSelect {
        li {
            display: flex;
            align-items: center;
            margin: 7px 0;
        }

        img {
            //width: 30px;
            width: 32px;
            height: auto;
            //border-radius: 5px;
            margin-right: 5px;
        }
    }

    .experimentalMenu.el-menu--horizontal {
        .el-menu {
            .el-menu-item,
            .el-submenu {

                font-size: 13px;
                line-height: 27px;
                height: 27px;
                padding: 0 7px;
                border-radius: 5px;
                color: $iq-color-text-primary;

                &:not(.is-disabled):hover, &:focus {
                    color: $iq-color-text-primary;
                    background: $iq-color-blue-light;
                }

                .el-submenu__title {
                    font-size: 13px !important;
                    line-height: 27px !important;
                    height: 27px !important;
                    color: $iq-color-text-primary !important;
                    background: transparent;
                }

                i {
                    color: $iq-color-blue;
                }
            }

            .minibutton {
                @include iq-button-sm;
                color: $iq-color-blue;
                font-variant: all-small-caps;
                font-size: 14px;
                cursor: pointer;
                margin: 5px -7px 0;

                &:hover {
                    background: $iq-color-blue-medium;
                }
            }
        }
    }

    .modalContent {
        padding: 15px;
    }
</style>
